import React, { useContext, useState } from 'react';
import './LightHeader.less';
import { ReactComponent as IconAiClipGenerator } from '../../assets/ai_clip_generator.svg';
import { useNavigate } from 'react-router';
import { UserContext } from '../../data/userContext';
import { Avatar, Button, Drawer, Image, Menu, Tooltip, Typography } from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';
import HeaderMenu from '../header/header-responsive/HeaderMenu/HeaderMenu';
import { Link } from 'react-router-dom';
import { useWindowDimensions } from '../../pages/Editor/Editor.page';
import { ReactComponent as Logo } from '../../assets/logo_sidebar.svg';
import { ReactComponent as IconHamburger } from '../../assets/hamburger.svg';
import { ReactComponent as IconClose } from '../../assets/close-circle.svg';
import { ReactComponent as IconSquareClock } from '../../assets/square_clock.svg';
import { ReactComponent as IconStreamCamera } from '../../assets/stream_camera.svg';
import { ReactComponent as IconRoundClock } from '../../assets/round_clock.svg';
import { ReactComponent as IconClockWithDots } from '../../assets/clock_with_dots.svg';
import { ReactComponent as IconSiteSearch } from '../../assets/site_search.svg';
import { isNumber } from 'lodash';
import { getOS, roundWithTwoDecimals, showGoProButton } from '../../utils';
import { ReactComponent as IconClock } from '../../assets/clock.svg';
import { Sidebar } from '../sidebar';
import { GOOGLE_USER } from '../../constants/user-constants';
import RightArrow from '../../assets/right-arrow.svg';
import ImageSearchShortcut from '../../assets/search_shortcut.png';
import { SettingsModal } from '../settings-modal';
import { ReactComponent as IconCrown } from '../../assets/crown_new.svg';
import { SiteSearchModal } from '../site-search-modal/SiteSearchModal';
import { SearchHotkeyListener } from '../site-search-modal/SearchHotkeyListener';
import { DISCORD_LINK } from '../../constants/content-constants';
import ThemeToggle from '../theme-toggle/ThemeToggle';


const LightHeader = ({ withoutSidebar = false }: { withoutSidebar?: boolean }) => {
  const [visible, setVisible] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { width } = useWindowDimensions();
  const isMobile = width < 1120;
  const userContext = useContext(UserContext);
  const subscriptions = userContext?.user?.subscriptions;
  const navigate = useNavigate();
  const isGoogleUser = !userContext?.user?.twitch_id;
  const usedStreams = userContext?.user?.used_streams;
  const usedMinutes = userContext?.user?.used_upload_minutes;
  const totalStreams = userContext?.user?.total_streams;
  const totalMinutes = userContext?.user?.total_upload_minutes;
  const hasSubscription = userContext?.user?.subscriptions && userContext?.user?.subscriptions[0];
  const isMacOs = Boolean(getOS() === 'MacOS');

  const getLogoHeight = () => {
    if (isMobile && location.pathname === '/') return 23;
    return isMobile ? 18 : 38;
  };

  const getLogoWidth = () => {
    if (isMobile && location.pathname === '/') return 127;
    return isMobile ? 80 : 160;
  };

  const styles = {
    logo: {
      width: getLogoWidth(),
      height: getLogoHeight(),
      background: `url(${Logo}) no-repeat center center`,
      backgroundSize: 'contain',
      margin: isMobile ? 0 : '10px 14px 10px 0',
      float: 'left',
    },
    spikesPro: {
      color: '#FFFE58',
      fontFamily: 'Poppins',
      fontSize: isMobile ? 8 : 16,
      fontWeight: 500,
    },
    drawerMenu: {
      background: 'transparent',
      border: 'none',
      flexDirection: 'column',
    },
    joinFree: {
      height: 55,
      width: 160,
      fontSize: 24,
      fontWeight: 600,
      margin: '0 auto'
    },
    upgradeContainerMenu: {
      borderRadius: 8,
      padding: 12,
      display: 'flex',
      alignItems: 'center',
      maxHeight: 46,
      minWidth: 144,
      boxShadow: 'var(--shadow-default)',
      background: 'var(--primary-gradient)',
      justifyContent: 'space-between',
    },
    goPro: {
      fontSize: 20,
      color: 'var(--text-on-color)',
    },
    getMinutesContainer: {
      borderRadius: 8,
      padding: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      maxHeight: 46,
      minWidth: 152,
      boxShadow: 'var(--shadow-default)',
      marginRight: 15
    },
    getMinutes: {
      fontSize: 20,
    },
    upgradeContainer: {
      borderRadius: 8,
      padding: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      maxHeight: 46,
      minWidth: 152,
      boxShadow: 'var(--shadow-default)',
      background: 'var(--primary-gradient)',
    },
  };

  const logout = () => {
    localStorage.removeItem('user');
    window.location.href = '/';
  };

  const toggleDrawer = () => {
    setVisible(prev => !prev);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleShowSettings = () => {
    onClose();
    setShowSettingsModal(true);
  };

  const handleNavigateToUploads = () => {
    onClose();
    navigate('/spikes/uploads');
  };

  const handleNavigateToStreams = () => {
    onClose();
    navigate('/spikes/streams');
  };

  const handleNavigateToVideoAI = () => {
    onClose();
    navigate('/spikes/videos');
  };
  const handleNavigateToTranscribe = () => {
    onClose();
    navigate('/spikes/transcribe');
  };
  const handleNavigateLoayaltyProgram = () => {
    onClose();
    navigate('loayalty-program');
  };

  const handleNavigateDashboard = () => {
    onClose();
    navigate('/dashboard');
  };

  const handleOpenSearchModal = () => {
    setIsSearchOpen(true);
  };

  const handleCloseSearchModal = () => {
    setIsSearchOpen(false);
  };

  const handleSearchHotkey = () => {
    handleOpenSearchModal();
  }


  const CreditsTimer = ({ isStreams = false }: { isStreams?: boolean }) => {
    const streamsLeft = isNumber(totalStreams) && isNumber(usedStreams) && roundWithTwoDecimals(totalStreams! - usedStreams!);
    const minutesLeft = isNumber(totalMinutes) && isNumber(usedMinutes) && roundWithTwoDecimals(totalMinutes! - usedMinutes!);

    const tooltipText = isStreams ? `You have ${streamsLeft} streams available` : `You have ${minutesLeft} minutes available`;

    if (!userContext?.user) return <></>;
    return (
      <Tooltip overlayClassName='tooltip' placement='bottom' title={tooltipText}>
        <div className="credits-timer">
          {isStreams ? <IconStreamCamera className='stream-camera-icon' /> : <IconClockWithDots className='square-clock-icon' />}
          <span>{isStreams ? `${totalStreams === 60 ? 'Unlimited' : streamsLeft} streams` : `${minutesLeft}m`}</span>
        </div>
      </Tooltip>
    );
  };

  return (
    <div className={`light-header ${isMobile && 'mobile'}`}>
      {isSearchOpen && <SiteSearchModal open={isSearchOpen} onClose={handleCloseSearchModal} />}
      <SearchHotkeyListener onHotkey={handleSearchHotkey} />
      <div style={withoutSidebar ? { paddingLeft: 0 } : {}} className='light-header__container'>
        {withoutSidebar || location.pathname.includes('/subscriptions') && !isMobile &&
          <div style={{ marginBottom: 0 }} className="big-sidebar__logo-container">
            <Logo className="big-sidebar__logo logo" onClick={() => navigate('/')} />
            {subscriptions?.length != 0 && subscriptions != null ? <span className='pro-label'>PRO</span> : <></>}
          </div>
        }
        {isMobile &&
          <div style={{ marginBottom: 0 }} className="big-sidebar__logo-container">
            <Logo className="big-sidebar__logo logo" onClick={() => navigate('/')} />
            {subscriptions?.length != 0 && subscriptions != null ? <span className='pro-label'>PRO</span> : <></>}
          </div>
        }
        <div className='left-placeholder' />
        <div className='light-header__right'>

          <div style={{ display: 'flex', alignItems: 'center', gap: 20, marginRight: 10 }} key="1">
            {!isGoogleUser && <CreditsTimer isStreams />}
            <CreditsTimer />
          </div>
          {(userContext?.user) && !isMobile && hasSubscription &&
            <div id='get-minutes-button' style={styles.getMinutesContainer} className="cursor-pointer primary-gradient-button" onClick={() => navigate('/dashboard')}>
              <Typography style={styles.getMinutes}>Get Minutes</Typography>
            </div>
          }

          {showGoProButton(userContext?.user) && !isMobile &&
            <div id='go-pro-button' style={styles.upgradeContainer} className="upgradeContainer cursor-pointer primary-gradient-button" onClick={() => navigate('/subscriptions')}>
              <Typography style={styles.goPro}>Go Pro</Typography>
              <IconCrown style={{ fill: 'var(--text-on-color)' }} />
            </div>
          }

          {!isMobile && !location.pathname.includes('/subscriptions') &&
            <div onClick={handleOpenSearchModal} className='site-search-box'>
              <IconSiteSearch />
              <span>Quick Search...</span>
              <div className="shortcut-box">
                {isMacOs ? '⌘K' : '^K'}
              </div>
            </div>
          }

          <ThemeToggle />

          <div style={{ display: 'flex', gap: 17 }}>
            <div className="mobile-menu">
              {/* {visible && <Sidebar isMobile />} */}
              {userContext?.user && (visible ? <IconClose onClick={toggleDrawer} /> : <IconHamburger onClick={toggleDrawer} />)}
              <Drawer
                title="Menu"
                className='main-drawer'
                placement="right"
                closable={true}
                onClose={onClose}
                visible={visible}
              >
                <Menu mode="inline" style={styles.drawerMenu as React.CSSProperties}>
                  {!userContext?.user ? (
                    <Menu.Item key="1" className="button-header">
                      <Button onClick={() => navigate('/')} style={styles.joinFree} type="primary" className="button">Start Now </Button>

                    </Menu.Item>
                  ) : null}
                  {showGoProButton(userContext?.user) &&
                    <Menu.Item className="go-pro-button" key="5" style={{ borderBottom: 'none !important' }}>
                      <div style={styles.upgradeContainerMenu} className="cursor-pointer yellow-hover" onClick={() => navigate('/subscriptions')}>
                        <Typography style={styles.goPro}>Go Pro</Typography>
                        <Image src={RightArrow} preview={false} />
                      </div>
                    </Menu.Item>
                  }
                  {!isGoogleUser &&
                    <Menu.Item onClick={handleNavigateToStreams} key="1">
                      <Typography>Live Stream AI</Typography>
                    </Menu.Item>
                  }
                  <Menu.Item onClick={handleNavigateToVideoAI} key="2">
                    <Typography>AI Clip Generator</Typography>
                  </Menu.Item>
                  <Menu.Item style={{ marginBottom: 30 }} onClick={handleNavigateToTranscribe} key="3">
                    <Typography>Add Captions</Typography>
                  </Menu.Item>
                  {/* <Menu.Item key="3">
                    <a
                      href={DISCORD_LINK}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Discord
                    </a>
                  </Menu.Item> */}
                  <Menu.Item key="4">
                    <a
                      href="https://intercom.help/spikes-studio/en/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      FAQ
                    </a>
                  </Menu.Item>
                  <Menu.Item onClick={handleShowSettings} key="5">
                    <Typography>Settings</Typography>
                  </Menu.Item>


                  <Menu.Item onClick={handleNavigateDashboard} key="10">
                    <Typography>Dashboard</Typography>
                  </Menu.Item>
                  {/* <Menu.Item onClick={handleNavigateLoayaltyProgram} key="10">
                    <Typography>Loyalty Program</Typography>
                  </Menu.Item>
                  <Menu.Item onClick={handleNavigateLoayaltyProgram} key="11">
                    <Link to="/about">About</Link>
                  </Menu.Item> */}
                  <Menu.Item onClick={logout} key="12">
                    <Typography>Logout</Typography>
                  </Menu.Item>
                </Menu>
              </Drawer>
            </div>
          </div>
          {!isMobile &&
            <>
              {userContext?.user ? (
                <>
                  <HeaderMenu logout={logout} />
                </>
              ) : (
                <Button id='start-now-header-button' onClick={() => navigate('/')} type="primary" className="button">Start Now</Button>
              )}
            </>
          }
        </div>
        {showSettingsModal && (
          <SettingsModal onClose={() => setShowSettingsModal(false)} />
        )}
      </div>
    </div>
  )
}

export default LightHeader;