import React, { useState, useMemo, useContext } from 'react';
import './Presets.less';
import { UserContext } from '../../../data/userContext';
import { useNavigate } from 'react-router';
import { IStreamer } from '../../../data/intefaces/streamer.interface';
import { getEnabledAnimatedCaptionsForUser } from '../../../utils';
import { PricingModal } from '../../../components/pricing-modal/PricingModal';
import { IClip, IClipEdit } from '../../../data/intefaces/stream.interface';
import { stylePresets } from '../../../constants/content-constants';
import { ReactComponent as IconStar } from '../../../assets/blurred-star.svg';

export interface IPresets {
  clip: IClip & Partial<IClipEdit>;
  handleChangePreset: (param: any) => void;
  selectedPreset: string | null;
  setSelectedPreset: (param: string) => void;
}

type TextTransform = 'uppercase' | 'lowercase' | 'none' | 'capitalize' | 'initial' | 'inherit';

const PRESETS = stylePresets;

const Presets = ({ clip, handleChangePreset, selectedPreset, setSelectedPreset }: IPresets) => {
  const [isPricingModalOpened, setIsPricingModalOpened] = useState(false);
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const handleOpenPricingModal = () => {
    setIsPricingModalOpened(true);
  };

  const handleClosePricingModal = () => {
    setIsPricingModalOpened(false);
  };

  const handleChoose = (preset: typeof PRESETS[0]) => {
    if (isPresetAvailableOnlyToProUser(preset)) {
      handleOpenPricingModal();
    } else {
      setSelectedPreset(preset.name);
      handleChangePreset(preset);
    }
  }

  const isPresetAvailableOnlyToProUser = (preset: typeof stylePresets[0]) => {
    if (userContext?.user?.permissions?.animated_captions && userContext?.user?.permissions?.animated_captions[0] === 'ALL') {
      return false;
    } else {
      if (preset.availableToAll) return false;
      else return true;
    }
  };

  return (
    <div className='presets'>
      <PricingModal isOpen={isPricingModalOpened} handleClosePricingModal={handleClosePricingModal} />

      <div className="presets__grid">
        {PRESETS.map(preset => (
          <div onClick={() => handleChoose(preset)} key={preset.name} className={`presets__item ${selectedPreset === preset.name && 'active'}`}>
            {isPresetAvailableOnlyToProUser(preset) && (
              <IconStar className='star-icon' />
            )}
            <div className="presets__item-content">
              <img
                src={preset?.editorGif || preset.gif}
                alt={preset.name}
              />
            </div>
            <div className="presets__item-footer">
              {preset.name}
            </div>

          </div>

        ))}
      </div>

    </div>
  );
};

export default Presets;
