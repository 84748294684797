import { Col, Row, Space, Image, Button, Typography, Divider, message, Spin, Tooltip } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import axios from 'axios';
import { COLORS } from '../../themes/colors';
import { VideoCrop } from '../../components/player';
import { SpikeScore } from '../../components/spike-score';
import { ButtonsGroup, ShareButton, ToggleButton } from '../../components/buttons';
import { IClip, IClipEdit, SubtitleWord, SubtitleSentence, IFeedScene } from '../../data/intefaces/stream.interface';
import { EGeneratedClipType } from '../../data/enums/clip-type.enum';
import { secondsToTime } from '../../utils/generic';
import { ResultModal } from '../../components/result-modal';
import { UserContext } from '../../data/userContext';
import { ContentFeed } from '../../components/content-feed';
import { MainLayout } from '../../layouts';
import { Footer as AppFooter } from '../../components/footer';
import { BrowserView, isBrowser } from 'react-device-detect';
import { Header } from '../../components/header';
import SubtitlesPanel, { IconButton } from './subtitles/subtitles-panel/SubtitlesPanel';
import SubtitlesPreview from './subtitles/subtitles-preview/SubtitlesPreview';
import { AlertOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import Loader from '../../assets/loader.gif';
import IconCopy from '../../assets/icon-copy.svg';
import IconCameraRoundTopRight from '../../assets/icon-round-camera-top-right.svg';
import IconBackArrow from '../../assets/icon-back-arrow.svg';
import IconCameraRoundTopRightSelected from '../../assets/icon-round-camera-top-right-selected.svg';
import IconCameraRoundBottomLeft from '../../assets/icon-round-camera-bottom-left.svg';
import IconCameraRoundBottomLeftSelected from '../../assets/icon-round-camera-bottom-left-selected.svg';
import IconCameraRoundBottomRight from '../../assets/icon-round-camera-bottom-right.svg';
import IconCameraRoundBottomRightSelected from '../../assets/icon-round-camera-bottom-right-selected.svg';
import IconCameraRoundTopLeft from '../../assets/icon-round-camera-top-left.svg';
import IconCameraRoundTopLeftSelected from '../../assets/icon-round-camera-top-left-selected.svg';
import IconWatermarkTopRight from '../../assets/icon-watermark-top-right.png';
import IconWatermarkTopRightSelected from '../../assets/icon-watermark-top-right-selected.png';
import IconWatermarkTopLeft from '../../assets/icon-watermark-top-left.png';
import IconWatermarkTopLeftSelected from '../../assets/icon-watermark-top-left-selected.png';
import IconWatermarkBottomLeft from '../../assets/icon-watermark-bottom-left.png';
import IconWatermarkBottomLeftSelected from '../../assets/icon-watermark-bottom-left-selected.png';
import IconWatermarkBottomRight from '../../assets/icon-watermark-bottom-right.png';
import IconWatermarkBottomRightSelected from '../../assets/icon-watermark-bottom-right-selected.png';
import ReloadIcon from '../../assets/reload-icon.svg';
import { ReactComponent as IconInfoCircle } from '../../assets/info-circle.svg';
import { ReactComponent as LogoIcon } from '../../assets/logo_sidebar.svg';
import { ReactComponent as DownloadIcon } from '../../assets/download-icon.svg';
import { ReactComponent as SmallPlayIcon } from '../../assets/small-play.svg';
import { ReactComponent as DropdownIndicatorIcon } from '../../assets/dropdown-indicator.svg';
import { ReactComponent as TemplateSaveIcon } from '../../assets/template-save.svg';
import { ReactComponent as TemplateReloadIcon } from '../../assets/template-reload.svg';
// import { ReactComponent as RightArrowIcon } from '../../assets/right-arrow-outline.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/right-arrow-plain.svg';
import { IEditorParams, IStreamer, ISubtitlesSettings } from '../../data/intefaces/streamer.interface';
import './Editor.less';
import './EditorPage.less';
import SwitchButton from '../../components/buttons/switch/SwitchButton';
import SubtitlesDropdown from './subtitles/subtitles-dropdown/SubtitlesDropdown';
import Tour from 'reactour';
import { compressData, decompressData, getEnabledElementsForUser, getEnabledGifsForUser, makeFontSizeResponsive, showGoProButton } from '../../utils';
import { EditorNavigator } from './EditorNavigator';
import { EditorTabNames, NO_SUBTITLES_MESSAGE } from '../../constants/content-constants';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { VideoTimeline } from '../../components/player/VideoTimeline';
import PlayerEditor from '../../components/player/PlayerEditor';
import { usePlayer } from '../../components/player/usePlayer';
import { default as _ReactPlayerClass } from 'react-player/lazy';
import Select, { DropdownIndicatorProps, components } from 'react-select';
import _ from 'lodash';


export const MAX_TRIMMING_RANGE = 300;

export interface ISpikePreviewModalProps {
  platformStreamId?: string;
  clip: IClip & Partial<IClipEdit>;
  preview?: boolean;
  fromSpikesPage?: boolean;
}

interface IDraggableParams {
  x: number;
  y: number;
  width: number;
  height: number;
}


interface TextSettings {
  word: string;
  background: string;
  start: number;
  end: number;
  color: string;
  textShadow: string;
  fontWeight: string;
  textTransform: string;
  fontSize: string;
  isHighlighted: boolean;
  fontFamily: string;
  alignItems: string;
  wordLevel: string;
}
interface subtitlesSetting {
  background: string;
  color: string;
  textShadow: string;
  fontWeight: string;
  textTransform: string;
  fontSize: string;
  isHighlighted: boolean;
  fontFamily: string;
  alignItems: string;
  wordLevel: string;
  captions_animation_type: string;
}

const defaultTextSettings: subtitlesSetting = {

  background: '#000000',
  color: '#FFFFFF',
  textShadow: 'Heavy',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontSize: 'Big',
  isHighlighted: false,
  alignItems: 'Middle',
  fontFamily: 'Impact',
  wordLevel: 'true',
  captions_animation_type: '',
};

const subtitles: SubtitleSentence[] = [{ 'start': 0, 'end': 0, 'text': NO_SUBTITLES_MESSAGE, 'words': [{ 'word': NO_SUBTITLES_MESSAGE, 'start': 0, 'end': 0, 'fontFamily': 'Helvetica', 'fontSize': '16', 'color': '#FFFFFF', 'background': 'none', 'isHighlighted': false, 'fontWeight': 'normal', 'textShadow': 'none', 'textTransform': 'none', 'alignItems': 'bottom' }] }];

// round and 1:1
const ROUND_CAMERA_CANBAS_DRAGGABLE_PARAMS: IDraggableParams = {
  x: 75,
  y: 20,
  width: 90,
  height: 90,
};

const CAMERA_16_9_CANBAS_DRAGGABLE_PARAMS: IDraggableParams = {
  x: 30,
  y: 20,
  width: 200,
  height: 112.5,
};

const DEFAULT_WEBCAM_POSITION: IDraggableParams = {
  x: 0,
  y: 0,
  width: 234.76851851851853,
  height: 132,
};

const DEFAULT_FEED_POSITION: IDraggableParams = {
  x: 248,
  y: 0,
  width: 453,
  height: 550
};

const DEFAULT_CANVAS_WEBCAM_POSITION: IDraggableParams = {
  x: 0,
  y: 0,
  width: 309,
  height: 174
};

const CAMERA_9_16_CANBAS_DRAGGABLE_PARAMS: IDraggableParams = {
  x: 10,
  y: 20,
  width: 100,
  height: 177.8,
};

const FEED_1_1_CANVAS_DRAGGABLE_PARAMS: IDraggableParams = {
  x: 30,
  y: 160,
  width: 200,
  height: 200
};

const FEED_16_9_CANVAS_DRAGGABLE_PARAMS: IDraggableParams = {
  x: 10,
  y: 160,
  width: 200,
  height: 112.5
};

const FEED_9_16_CANVAS_DRAGGABLE_PARAMS: IDraggableParams = {
  x: 10,
  y: 160,
  width: 100,
  height: 177.8
};

const FEED_16_19_CANVAS_DRAGGABLE_PARAMS: IDraggableParams = {
  height: 118.75,
  width: 100,
  x: 10,
  y: 160
};

const ROUND_CAMERA_POSITION_BUTTONS = [
  {
    label: '',
    value: 'top-right',
    icon: IconCameraRoundTopRight,
    iconSelected: IconCameraRoundTopRightSelected,
  },
  {
    label: '',
    value: 'bottom-left',
    icon: IconCameraRoundBottomLeft,
    iconSelected: IconCameraRoundBottomLeftSelected,
  },
  {
    label: '',
    value: 'bottom-right',
    icon: IconCameraRoundBottomRight,
    iconSelected: IconCameraRoundBottomRightSelected,
  },
  {
    label: '',
    value: 'top-left',
    icon: IconCameraRoundTopLeft,
    iconSelected: IconCameraRoundTopLeftSelected,
  }
];

const WATERMARK_POSITION_BUTTONS = [
  {
    label: '',
    value: 'top-right',
    icon: IconWatermarkTopRight,
    iconSelected: IconWatermarkTopRightSelected,
  },
  {
    label: '',
    value: 'bottom-left',
    icon: IconWatermarkBottomLeft,
    iconSelected: IconWatermarkBottomLeftSelected,
  },
  {
    label: '',
    value: 'bottom-right',
    icon: IconWatermarkBottomRight,
    iconSelected: IconWatermarkBottomRightSelected,
  },
  {
    label: '',
    value: 'top-left',
    icon: IconWatermarkTopLeft,
    iconSelected: IconWatermarkTopLeftSelected,
  }
];

const webcam_ratio_menu_items = [
  {
    label: '16 : 9',
    value: '1.777777777777778'
  },
  {
    label: '1 : 1 ',
    value: '1',
  },
  {
    label: '9 : 16',
    value: '0.5625'
  },
  {
    label: 'Circle',
    value: '0'
  },
  {
    label: 'None',
    value: 'none'
  }
];

const content_ratio_menu_items = [
  {
    label: '16 : 9',
    value: '1.777777777777778'
  },
  {
    label: '1 : 1',
    value: '1',
  },
  {
    label: '9 : 16',
    value: '0.5625'
  },
  {
    label: '16 : 19',
    value: '0.8228573309388185'
  }
];

const convertFeedTypeToRatio = (value: any) => {
  switch (value) {
    case 'round': {
      return '1';
    }
    case 'landscape': {
      return '1.777777777777778';
    }
    case 'square': {
      return '1';
    }
    case 'portrait': {
      return '0.5625';
    }
    case 'regular': {
      return '1.777777777777778';
    }
    case 'rectangle': {
      return '0.8228573309388185';
    }
    default: {
      return '1.777777777777778';
    }
  }
};

const convertFeedTypeToWebCamMenuItemRatio = (value: any) => {
  switch (value) {
    case 'round': {
      return '0';
    }
    case 'landscape': {
      return '1.777777777777778';
    }
    case 'square': {
      return '1';
    }
    case 'portrait': {
      return '0.5625';
    }
    case 'regular': {
      return '1.777777777777778';
    }
    default: {
      return '1.777777777777778';
    }
  }
};

const convertRatioToFeedType = (value: any) => {
  switch (value) {
    case '0': {
      return 'round';
    }
    case '1.777777777777778': {
      return 'landscape';
    }
    case '1': {
      return 'square';
    }
    case '0.5625': {
      return 'portrait';
    }
    case '0.8228573309388185': {
      return 'rectangle';
    }
    default: {
      return 'landscape';
    }
  }
};

const feedSettingsPlaceholder = {
  startTime: 0,
  endTime: 0,
  webcam_enabled: false,
  webcam_x_coordinate: 0,
  webcam_y_coordinate: 0,
  webcam_height: 0,
  webcam_width: 0,
  webcam_type: null,
  webcam_position: 0,
  feed_x_coordinate: 0,
  feed_y_coordinate: 0,
  feed_height: 0,
  feed_width: 0,
  webcam_output_x: 0,
  webcam_output_y: 0,
  webcam_output_height: 0,
  webcam_output_width: 0,
  feed_output_x: 0,
  feed_output_y: 0,
  feed_output_height: 0,
  feed_output_width: 0,
  feed_type: 0,
};

const FEATURE_SLIDER_COLORS = ['rgba(255, 177, 177, 0.70)', 'rgba(103, 255, 204, 0.70)', 'rgba(255, 253, 3, 0.70)', 'rgba(255, 87, 51, 0.70)'];

export const useFocus = () => {
  const htmlElRef: any = useRef(null)
  const setFocus = () => {
    htmlElRef.current && htmlElRef?.current.focus()
  }

  return [htmlElRef, setFocus]
}


export const EditorPage = (props: ISpikePreviewModalProps) => {
  const { platformStreamId, clip, preview = false } = props;
  const [sentences, setSentences] = useState(subtitles);
  const [subtitlesSettings, setSubtitlesSettings] = useState(clip.subtitles_settings ? clip.subtitles_settings : defaultTextSettings);
  const [subtitleState, setSubtitleState] = useState(clip.subtitles_enabled);
  const [emojiEnabled, setEmojiEnabled] = useState(clip.subtitles_enabled && clip.emojis_enabled && process.env.REACT_APP_DISABLE_EMOJIS !== 'true');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [trimValues, setTrimValues] = useState<[number, number]>([0, 42]);
  const [openTour, setOpenTour] = useState<boolean>(false);
  const [activeEditorTab, setActiveEditorTab] = useState(EditorTabNames.BASIC_EDITOR);
  const [loadingSubtitles, setLoadingSubtitles] = useState(false);
  const [animation, setAnimation] = useState(clip?.captions_animation_type || 'none');
  const [playerProgress, setPlayerProgress] = useState(0);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [currentSeekedTimeOfMainPlayer, setCurrentSeekedTimeOfMainPlayer] = useState(-1);
  const [videoRes, setVideoRes] = useState<{ width: number, height: number } | null>(null);
  const [featureSliders, setFeatureSliders] = useState<any>([]);
  const [showSplitSceneModal, setShowSplitSceneModal] = useState(false);
  const [sceneSplitTutorialShown, setSceneSplitTutorialShown] = useState(false);
  const [playerLoadedSeconds, setPlayerLoadedSeconds] = useState(0); // Buffering
  // const sceneSplitTutorialShown = localStorage.getItem('sceneSplitTutorialShown');

  const trimValuesRef = useRef<[number, number]>(trimValues); // Ref to store the latest duration value

  const rightToLeft = clip?.subtitles_orientation === 'right';
  const editorRef = useRef<HTMLDivElement | null>(null);
  const [isEditorFocused, setIsEditorFocused] = useState(false);

  useEffect(() => {
    trimValuesRef.current = trimValues;
  }, [trimValues]);

  useEffect(() => {
    // Add overflow: hidden to body
    document.body.style.overflow = 'hidden';

    // Cleanup function to reset the overflow when the component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement;
      if (
        editorRef.current && !targetElement.closest('.left-panel-container')
      ) {
        setIsEditorFocused(true);
      } else {
        setIsEditorFocused(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [editorRef]);

  useEffect(() => {
    // For older clips we don't have `edit_properties`field, so we need to prefill it manually
    if (!clip.edit_properties || (clip?.edit_properties && clip?.edit_properties?.length === 0)) {
      clip.edit_properties = [
        {
          start_time_in_event: 0,
          end_time_in_event: clip.end_time - clip.start_time,
          webcam_enabled: clip.webcam_enabled,
          webcam_x_coordinate: clip.webcam_x_coordinate, // Draggable 2
          webcam_y_coordinate: clip.webcam_y_coordinate,
          webcam_height: clip.webcam_height,
          webcam_width: clip.webcam_width,
          webcam_type: clip.webcam_type,
          webcam_position: clip.webcam_position,
          feed_x_coordinate: clip.feed_x_coordinate, // Draggable 1
          feed_y_coordinate: clip.feed_y_coordinate,
          feed_height: clip.feed_height,
          feed_width: clip.feed_width,
          webcam_output_x: clip.webcam_output_x, // Draggable 3
          webcam_output_y: clip.webcam_output_y,
          webcam_output_height: clip.webcam_output_height,
          webcam_output_width: clip.webcam_output_width,
          feed_output_x: clip.feed_output_x, // Draggable 4
          feed_output_y: clip.feed_output_y,
          feed_output_height: clip.feed_output_height,
          feed_output_width: clip.feed_output_width,
          feed_type: clip.feed_type,
          blurred_background: clip.blurred_background || true,
          hidden_scene: false
        },
      ];
    } else {
      if (typeof clip.edit_properties === 'string') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (clip.edit_properties && JSON.parse(clip.edit_properties)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          clip.edit_properties = JSON.parse(clip.edit_properties);
        }
      }
    }
  }, []);

  const calculateWindowSizeRatio = () => {
    if (width > 3350) return 10500;
    else if (width > 3280) return 10000;
    else if (width >= 3050) return 9700;
    else if (width > 2850) return 9300;
    else if (width > 2700) return 9000;
    else if (width > 2600) return 8700;
    else if (width > 2580) return 8600;
    else if (width > 2400) return 8400;
    else if (width > 2300) return 8100;
    else if (width > 2100) return 7900;
    else if (width > 2000) return 7500;
    else if (width > 1900) return 7300;
    else if (width > 1700) return 7150;
    else if (width > 1400) return 6900;
    else if (width > 1100) return 7000;
    else return 6500;
  };

  const playerRef = useRef<any>(null);

  const { width, height } = useWindowDimensions();

  const internalPlayer = playerRef.current?.getInternalPlayer() as HTMLVideoElement;

  const videoWidth = internalPlayer?.videoWidth;
  const videoHeight = internalPlayer?.videoHeight;

  const VIDEO_WIDTH_RATIO = (videoWidth / videoHeight) || 1.776909090909091; // constant
  const VIDEO_MOBILE_PREVIEW_WIDTH_RATIO = 0.563454545454545; // constant
  const WINDOW_SIZE_RATIO = calculateWindowSizeRatio();
  const VIDEO_HEIGHT_RATIO = Math.round((WINDOW_SIZE_RATIO / width) * 10) / 10; // The whole width of the browser / ratio = dynamic video height

  const calculateMaxVideoHeight = () => {
    const hardcoded = height - 420 < 1200 ? height - 420 : 1200;
    return hardcoded;
  }
  const MAX_VIDEO_HEIGHT = calculateMaxVideoHeight(); // Max height of video so that everything fits on ultra wide screen with small height (400px - height of other elements on the screen except for player)

  const isSmallScreen = width <= 2250;
  const isTablet = width <= 1971;

  const [VIDEO_HEIGHT, setVIDEO_HEIGHT] = useState(width / VIDEO_HEIGHT_RATIO <= MAX_VIDEO_HEIGHT ? width / VIDEO_HEIGHT_RATIO : MAX_VIDEO_HEIGHT);
  const [VIDEO_WIDTH, setVIDEO_WIDTH] = useState(VIDEO_WIDTH_RATIO * VIDEO_HEIGHT);
  // let VIDEO_HEIGHT = width / VIDEO_HEIGHT_RATIO <= MAX_VIDEO_HEIGHT ? width / VIDEO_HEIGHT_RATIO : MAX_VIDEO_HEIGHT;
  // let VIDEO_WIDTH = VIDEO_WIDTH_RATIO * VIDEO_HEIGHT;

  const MOBILE_VIDEO_WIDTH = VIDEO_MOBILE_PREVIEW_WIDTH_RATIO * VIDEO_HEIGHT;

  const MAX_DESKTOP_VIDEO_WIDTH = width - MOBILE_VIDEO_WIDTH - 420 - 140 - 64;
  const MAX_DESKTOP_VIDEO_HEIGHT = MAX_DESKTOP_VIDEO_WIDTH / VIDEO_WIDTH_RATIO;

  const DEFAULT_WEBCAM_HEIGHT_RATIO = 0.316363636363636; // VIDEO_HEIGHT * ratio = default WEBCAM height
  const DEFAULT_WEBCAM_HEIGHT = DEFAULT_WEBCAM_HEIGHT_RATIO * VIDEO_HEIGHT;

  useEffect(() => {
    if (VIDEO_HEIGHT > MAX_DESKTOP_VIDEO_HEIGHT) {
      setVIDEO_HEIGHT(MAX_DESKTOP_VIDEO_HEIGHT);
      setVIDEO_WIDTH(MAX_DESKTOP_VIDEO_WIDTH);
    }
  }, [MAX_DESKTOP_VIDEO_HEIGHT, MAX_DESKTOP_VIDEO_WIDTH, VIDEO_HEIGHT]);

  useEffect(() => {
    if (VIDEO_HEIGHT <= MAX_DESKTOP_VIDEO_HEIGHT) {
      const resultHeight = width / VIDEO_HEIGHT_RATIO <= MAX_VIDEO_HEIGHT ? width / VIDEO_HEIGHT_RATIO : MAX_VIDEO_HEIGHT;
      const resultWidth = VIDEO_WIDTH_RATIO * VIDEO_HEIGHT;

      if (VIDEO_HEIGHT !== resultHeight || VIDEO_WIDTH !== resultWidth) {
        setVIDEO_HEIGHT(resultHeight);
        setVIDEO_WIDTH(resultWidth);
      }
    }
  }, [videoRes]);


  const DEFAULT_FEED_POSITION: IDraggableParams = {
    x: 248,
    y: 0,
    width: MOBILE_VIDEO_WIDTH,
    height: MOBILE_VIDEO_WIDTH / 0.8228573309388185 // Rectangle ratio
  };

  const DEFAULT_CANVAS_FEED_POSITION: IDraggableParams = {
    x: 0,
    y: DEFAULT_WEBCAM_HEIGHT,
    width: MOBILE_VIDEO_WIDTH,
    height: MOBILE_VIDEO_WIDTH / 0.8228573309388185 // Rectangle ratio
  };

  const DEFAULT_CANVAS_WEBCAM_POSITION: IDraggableParams = {
    x: 0,
    y: 0,
    width: MOBILE_VIDEO_WIDTH,
    height: DEFAULT_WEBCAM_HEIGHT
  };

  const DEFAULT_WEBCAM_POSITION: IDraggableParams = {
    x: 0,
    y: 0,
    width: MOBILE_VIDEO_WIDTH,
    height: DEFAULT_WEBCAM_HEIGHT,
  };

  const normalize = useCallback((value: number) => {
    return videoRes ? Math.floor(value * videoRes?.height / VIDEO_HEIGHT) : value * VIDEO_HEIGHT;
  }, [videoRes, VIDEO_HEIGHT]);

  const downsize = useCallback((value: number, isHeight?: boolean) => {
    const result = videoRes ? value / (videoRes?.height / VIDEO_HEIGHT) : value / VIDEO_HEIGHT;
    // if (result > VIDEO_HEIGHT && isHeight) return VIDEO_HEIGHT;
    return result;
  }, [videoRes, VIDEO_HEIGHT]);

  const normalizeCanvas = (value: number) => {
    return Math.floor(value * 1920 / VIDEO_HEIGHT);
    // return Math.floor(value * (videoRes?.width || 1920) / VIDEO_HEIGHT); // POSSIBLE SOLUTION FOR DYNAMIC RATIO VIDEOS
  };

  const downsizeCanvas = (value: any) => {
    return value / (1920 / VIDEO_HEIGHT);
    // return value / ((videoRes?.width || 1920) / VIDEO_HEIGHT); // POSSIBLE SOLUTION FOR DYNAMIC RATIO VIDEOS
  };

  // Feed Scenes methods (start)
  //--------
  const [allFeedScenes, setAllFeedScenes] = useState<IFeedScene[]>([] as unknown as IFeedScene[]);

  useEffect(() => {
    if (allFeedScenes.length === 1 && allFeedScenes[0]?.hidden_scene) {
      handleRevertFeedScene(0);
    }
  }, [allFeedScenes]);

  // Protects if rectange is too big (usually happens for lower resolution videos [check `videoRes`])
  const protectDownsizeFeedHeight = (height: number, isHeight?: boolean) => {
    const downsized = downsize(height, isHeight);
    if (downsized - 1 > VIDEO_HEIGHT) {
      return downsized / 2;
    } else return downsized;
  }

  // Protects if rectange is too big (usually happens for lower resolution videos [check `videoRes`])
  const protectDownsizeFeedWidth = (width: number, isHeight?: boolean) => {
    const downsized = downsize(width, isHeight);
    if (downsized - 1 > VIDEO_WIDTH) {
      return downsized / 2;
    } else return downsized;
  }

  const protectDownsizeFeedX = (x: number, width: number, isHeight?: boolean) => {
    const downsizedWidth = downsize(width, isHeight);

    if (downsizedWidth - 1 > VIDEO_WIDTH) {
      return downsize(x / 2);
    } else return downsize(x);
  }

  /* Downsize all values from the beginning and save into `allFeedScenes` */
  useEffect(() => {
    if (clip.edit_properties && Array.isArray(clip.edit_properties)) {
      const downsizedFeedSettingsList = clip.edit_properties?.map(feed => ({
        ...feed,
        webcam_x_coordinate: downsize(feed.webcam_x_coordinate || 0),
        webcam_y_coordinate: downsize(feed.webcam_y_coordinate || 0),
        webcam_width: downsize(feed.webcam_width || 0),
        webcam_height: downsize(feed.webcam_height || 0),

        webcam_output_x: downsizeCanvas(feed.webcam_output_x || 0),
        webcam_output_y: downsizeCanvas(feed.webcam_output_y || 0),
        webcam_output_width: downsizeCanvas(feed.webcam_output_width || 0),
        webcam_output_height: downsizeCanvas(feed.webcam_output_height || 0),

        feed_x_coordinate: protectDownsizeFeedX(feed.feed_x_coordinate || 0, feed.feed_width || 0),
        feed_y_coordinate: downsize(feed.feed_y_coordinate || 0),
        feed_width: protectDownsizeFeedWidth(feed.feed_width || 0),
        feed_height: protectDownsizeFeedHeight(feed.feed_height || 0),

        feed_output_x: downsizeCanvas(feed.feed_output_x || 0),
        feed_output_y: downsizeCanvas(feed.feed_output_y || 0),
        feed_output_width: downsizeCanvas(feed.feed_output_width || 0),
        feed_output_height: downsizeCanvas(feed.feed_output_height || 0),

        blurred_background: feed.blurred_background || true
      }));
      setAllFeedScenes(downsizedFeedSettingsList);
    } else if (typeof clip.edit_properties === 'string') {
      try {
        const parsed = JSON.parse(clip.edit_properties);
        if (Array.isArray(parsed)) {
          clip.edit_properties = parsed;
          const downsizedFeedSettingsList = parsed?.map(feed => ({
            ...feed,
            webcam_x_coordinate: downsize(feed.webcam_x_coordinate || 0),
            webcam_y_coordinate: downsize(feed.webcam_y_coordinate || 0),
            webcam_width: downsize(feed.webcam_width || 0),
            webcam_height: downsize(feed.webcam_height || 0),

            webcam_output_x: downsizeCanvas(feed.webcam_output_x || 0),
            webcam_output_y: downsizeCanvas(feed.webcam_output_y || 0),
            webcam_output_width: downsizeCanvas(feed.webcam_output_width || 0),
            webcam_output_height: downsizeCanvas(feed.webcam_output_height || 0),

            feed_x_coordinate: protectDownsizeFeedX(feed.feed_x_coordinate || 0, feed.feed_width || 0),
            feed_y_coordinate: downsize(feed.feed_y_coordinate || 0),
            feed_width: protectDownsizeFeedWidth(feed.feed_width || 0),
            feed_height: protectDownsizeFeedHeight(feed.feed_height || 0),

            feed_output_x: downsizeCanvas(feed.feed_output_x || 0),
            feed_output_y: downsizeCanvas(feed.feed_output_y || 0),
            feed_output_width: downsizeCanvas(feed.feed_output_width || 0),
            feed_output_height: downsizeCanvas(feed.feed_output_height || 0),

            blurred_background: feed.blurred_background || true
          }));
          setAllFeedScenes(downsizedFeedSettingsList);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [clip.edit_properties, videoRes?.height, VIDEO_HEIGHT, VIDEO_WIDTH]);

  const getCurrentFeedByProgress = (progress: number) => {
    const currentFeedScene = allFeedScenes.find(obj => obj.start_time_in_event <= progress && progress < obj.end_time_in_event);
    return currentFeedScene || (allFeedScenes && allFeedScenes[allFeedScenes.length - 1]) || feedSettingsPlaceholder;
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const currentFeedScene: IFeedScene = getCurrentFeedByProgress(playerProgress);

  const updateFeedSceneFieldByName = (fieldName: string, updatedValue: any) => {
    setAllFeedScenes(prevSettings => prevSettings.map(feed => {
      if (feed.start_time_in_event === currentFeedScene.start_time_in_event) {
        return { ...feed, [fieldName]: updatedValue };
      }
      return feed;
    }));
  };

  const updateFeedSceneFieldByNameAndStartTime = (startTime: number, fieldName: string, updatedValue: any) => {
    setAllFeedScenes(prevSettings => prevSettings.map(feed => {
      if (feed.start_time_in_event === startTime) {
        return { ...feed, [fieldName]: updatedValue };
      }
      return feed;
    }));
  };

  const updateWebcamEnabled = (isEnabled: boolean) => {
    updateFeedSceneFieldByName('webcam_enabled', Boolean(isEnabled));
  };

  const updateWebcamType = (type: string) => {
    updateFeedSceneFieldByName('webcam_type', type);
  };

  const updateFeedType = (type: string, withoutSidework?: boolean) => {
    updateFeedSceneFieldByName('feed_type', type);
    if (!withoutSidework) updateFeedTypeSideWork(type);
  };

  const updateDraggableFirst = (coordinates: { x: number, y: number, width: number, height: number }) => {
    const { x, y, width, height } = coordinates;
    updateFeedSceneFieldByName('feed_x_coordinate', x);
    updateFeedSceneFieldByName('feed_y_coordinate', y);
    updateFeedSceneFieldByName('feed_width', width);
    updateFeedSceneFieldByName('feed_height', height);
  };

  const updateDraggableSecond = (coordinates: { x: number, y: number, width: number, height: number }) => {
    const { x, y, width, height } = coordinates;
    updateFeedSceneFieldByName('webcam_x_coordinate', x);
    updateFeedSceneFieldByName('webcam_y_coordinate', y);
    updateFeedSceneFieldByName('webcam_width', width);
    updateFeedSceneFieldByName('webcam_height', height);
  };

  const updateDraggableThird = (coordinates: { x: number, y: number, width: number, height: number }) => {
    const { x, y, width, height } = coordinates;
    updateFeedSceneFieldByName('webcam_output_x', x);
    updateFeedSceneFieldByName('webcam_output_y', y);
    updateFeedSceneFieldByName('webcam_output_width', width);
    updateFeedSceneFieldByName('webcam_output_height', height);
  };

  const updateDraggableFourth = (coordinates: { x: number, y: number, width: number, height: number }) => {
    const { x, y, width, height } = coordinates;
    updateFeedSceneFieldByName('feed_output_x', x);
    updateFeedSceneFieldByName('feed_output_y', y);
    updateFeedSceneFieldByName('feed_output_width', width);
    updateFeedSceneFieldByName('feed_output_height', height);
  };

  const draggableFirst = {
    x: currentFeedScene?.feed_x_coordinate || 0,
    y: currentFeedScene?.feed_y_coordinate || 0,
    height: currentFeedScene?.feed_height || 0,
    width: currentFeedScene?.feed_width || 0
  };

  const draggableSecond = {
    x: currentFeedScene?.webcam_x_coordinate || 0,
    y: currentFeedScene?.webcam_y_coordinate || 0,
    height: currentFeedScene?.webcam_height || 0,
    width: currentFeedScene?.webcam_width || 0
  };

  const draggableThird = {
    x: currentFeedScene?.webcam_output_x || 0,
    y: currentFeedScene?.webcam_output_y || 0,
    height: currentFeedScene?.webcam_output_height || 0,
    width: currentFeedScene?.webcam_output_width || 0,
  };

  const draggableFourth = {
    x: currentFeedScene?.feed_output_x || 0,
    y: currentFeedScene?.feed_output_y || 0,
    height: currentFeedScene?.feed_output_height || 0,
    width: currentFeedScene?.feed_output_width || 0,
  };

  const handleSplitVideo = (splitTime: number) => {
    if (!sceneSplitTutorialShown) {
      setShowSplitSceneModal(true);
      // localStorage.setItem('sceneSplitTutorialShown', 'true');
      setSceneSplitTutorialShown(true);
    }
    if (splitTime > currentFeedScene.start_time_in_event && splitTime < currentFeedScene.end_time_in_event) {
      const currentFeedCopy = { ...currentFeedScene };
      updateFeedSceneFieldByName('end_time_in_event', splitTime);
      currentFeedCopy.start_time_in_event = splitTime;
      setAllFeedScenes(prev => [...prev, currentFeedCopy].sort((a, b) => a.start_time_in_event - b.start_time_in_event));
    }
  };

  const addSceneByBreakpoints = (startTime: number, endTime: number, hideOtherScenes?: boolean, hideNewScene?: boolean) => {
    // Mergin scenes if new scene is in between the existing scenes
    allFeedScenes.forEach(({ start_time_in_event, end_time_in_event }) => {
      if ((startTime < start_time_in_event && start_time_in_event < endTime) || (startTime <= end_time_in_event && end_time_in_event <= endTime)) {
        handleDeleteFeedScene(start_time_in_event);
      }
    });

    // Added setTimeout so the the code above^ has time to proceed
    setTimeout(() => {
      setAllFeedScenes(prev => {
        const copyAllScenes = [...prev];
        copyAllScenes.forEach((parentScene) => {
          if (hideOtherScenes) parentScene.hidden_scene = true;
          // Finding scene, inside of which we will place our new scene
          if (parentScene.start_time_in_event <= startTime && endTime <= parentScene.end_time_in_event) {
            const sceneOnTheRight = { ...parentScene };
            const newScene = { ...parentScene };
            newScene.start_time_in_event = startTime;
            newScene.end_time_in_event = endTime;

            if (hideOtherScenes) newScene.hidden_scene = false;
            else if (hideNewScene) newScene.hidden_scene = true;

            sceneOnTheRight.start_time_in_event = endTime;

            parentScene.end_time_in_event = startTime;

            copyAllScenes.push(newScene);
            copyAllScenes.push(sceneOnTheRight);
          }
        })
        const sortedScenes = copyAllScenes.sort((a, b) => a.start_time_in_event - b.start_time_in_event);
        if (startTime === 0) {
          sortedScenes.shift(); // If selected words are in the beginning of the subtitles, delete first scene, which is 0->0
        }
        return sortedScenes;
      })
      if (hideOtherScenes) {
        setTrimValues([startTime, endTime]);
        onSeek(startTime);
      }
    }, 100);

    setTimeout(() => {
      if (hideOtherScenes) handleRevertFeedScene(startTime);
    }, 200);
  }

  const makeClipFromSelectedWords = (startTime: number, endTime: number) => {
    addSceneByBreakpoints(startTime, endTime, true);
  }

  const hideSelectedWords = (startTime: number, endTime: number) => {
    addSceneByBreakpoints(startTime, endTime, false, true);
  }

  const handleSplitSilentMoments = (breakpointsArray: any[], withoutHiding?: boolean) => {
    const fullDuration = currentFeedScene.end_time_in_event;
    const allScenesResult: IFeedScene[] = [...allFeedScenes];

    if (breakpointsArray[0] === 0) {
      updateFeedSceneFieldByName('start_time_in_event', breakpointsArray[1]);
    } else {
      updateFeedSceneFieldByName('end_time_in_event', breakpointsArray[0]);
    }

    breakpointsArray.forEach((number, index) => {
      const currentFeedCopy = { ...currentFeedScene };
      currentFeedCopy.start_time_in_event = number;
      currentFeedCopy.end_time_in_event = breakpointsArray[index + 1] || fullDuration;

      if (index % 2 === 0 && !withoutHiding) {
        currentFeedCopy.hidden_scene = true;
      }


      setAllFeedScenes(prev => [...prev, currentFeedCopy].sort((a, b) => a.start_time_in_event - b.start_time_in_event));
      allScenesResult.push(currentFeedCopy);
      if (breakpointsArray[0] === 0 && !withoutHiding) {
        handleHideFeedScene(0); // Hide first scene
      }
    })
    return allScenesResult.sort((a, b) => a.start_time_in_event - b.start_time_in_event);
  };

  const handleCloseSplitSceneModal = () => {
    setShowSplitSceneModal(false);
  };

  const handleHideFeedScene = (startTime: number) => {
    setAllFeedScenes(prev => {
      const copyScenes = [...prev];
      copyScenes.forEach(feed => {
        if (feed.start_time_in_event === startTime) {
          feed.hidden_scene = true;
        }
      });
      return [...copyScenes];
    });
  };

  const handleRevertFeedScene = (startTime: number) => {
    setAllFeedScenes(prev => {
      const copyScenes = [...prev];
      copyScenes.forEach(feed => {
        if (feed.start_time_in_event === startTime) {
          feed.hidden_scene = false;
        }
      });
      return [...copyScenes];
    });
  };

  const handleDeleteFeedScene = useCallback((startTime: number) => {
    if (startTime === 0) return;
    setAllFeedScenes(prev => {
      const copy = [...prev];
      if (copy.length === 1) {
        return copy;
      }
      const sceneToDelete = copy.find(feed => feed.start_time_in_event === startTime);
      const arrayWithoutScene = copy.filter(feed => feed.start_time_in_event !== startTime);

      const sceneBeforeDeleted = arrayWithoutScene.find(feed => feed.end_time_in_event === startTime);
      const sceneAfterDeleted = arrayWithoutScene.find(feed => feed.start_time_in_event === sceneToDelete?.end_time_in_event);

      if (sceneBeforeDeleted) {
        arrayWithoutScene.forEach(feed => {
          if (feed.end_time_in_event === startTime) {
            feed.end_time_in_event = sceneToDelete?.end_time_in_event;
          }
        });
      } else if (sceneAfterDeleted) {
        arrayWithoutScene.forEach(feed => {
          if (feed.start_time_in_event === sceneToDelete?.end_time_in_event) {
            feed.start_time_in_event = sceneToDelete?.start_time_in_event;
          }
        });
      }
      return [...arrayWithoutScene];
    });
  }, [allFeedScenes]);
  //--------
  // Feed Scenes methods (end)

  /* Add feature slider when animation is applied */
  useEffect(() => {
    const copyAnimation = animation;
    const foundSliderWithAnimation = featureSliders.find((slider: any) => slider.type === 'animation');

    // Handle remove existing animation and add new to array
    if (copyAnimation) {
      if (foundSliderWithAnimation) {
        handleDeleteFeatureSlider(foundSliderWithAnimation.id);
      }
      addAnimationToFeatureSliders(copyAnimation);
    }
  }, [animation]);

  useEffect(() => {
    // Pre-populate 'animation' from featureSliders on editor load
    if (featureSliders && featureSliders.length > 0) {
      const animationSlider = featureSliders.find((slider: any) => slider.type === 'animation');
      const splitStr = animationSlider?.featureName?.split('Captions Animation - ');
      if (splitStr && splitStr[1]) {
        const name = splitStr[1];
        if (!animation && name) {
          setAnimation(name);
        }
      }
    }
  }, [featureSliders]);

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * FEATURE_SLIDER_COLORS.length);
    return FEATURE_SLIDER_COLORS[randomIndex];
  };

  const handleDeleteFeatureSlider = useCallback((id: any) => {
    const sliderToBeDeleted = featureSliders.find((slider: any) => slider.id === id);
    setFeatureSliders((prev: any[]) => prev.filter(item => item.id !== id));

    // Remove animation if animation slider is deleted
    if (sliderToBeDeleted.type === 'animation') {
      setAnimation('');
    }
  }, [featureSliders]);

  const updateSliderFeatureMobileDimension = (id: any, newDimension: any) => {
    setFeatureSliders((currentSliders: any) =>
      currentSliders.map((slider: any) =>
        slider.id === id ? { ...slider, mobileDymension: { ...slider.mobileDymension, ...newDimension } } : slider
      )
    );
  };

  const updateSliderFeatureDesktopDimension = (id: any, newDimension: any) => {
    setFeatureSliders((currentSliders: any) =>
      currentSliders.map((slider: any) =>
        slider.id === id ? { ...slider, desktopDymension: { ...slider.desktopDymension, ...newDimension } } : slider
      )
    );
  };

  const handleChooseBRollImage = useCallback((imageUrl: string, name: string, ratio: number) => {
    addABRollImageToFeatureSliders(imageUrl, name, ratio);
  }, [playerProgress, trimValues]);

  const handleChooseGifs = useCallback((imageUrl: string, name: string, ratio: number) => {
    addABRollImageToFeatureSliders(imageUrl, name, ratio);
  }, [playerProgress, trimValues]);

  const handleChooseSocialLogo = useCallback((text: string, name: string, ratio: number) => {
    addASocialLogoToFeatureSliders(text, name, ratio);
  }, [playerProgress, trimValues]);

  const handleChooseBRollVideo = useCallback((videoUrl: string, name: string, duration: number, ratio: number) => {
    addABRollVideoToFeatureSliders(videoUrl, name, duration, ratio);
  }, [playerProgress, trimValues]);

  const handleChooseWatermark = useCallback((imageUrl: string, ratio: number, position: string) => {
    addWatermarkToFeatureSliders(imageUrl, ratio, position);
  }, [playerProgress, trimValues]);

  // Animation item Generator
  const addAnimationToFeatureSliders = (animation: string) => {
    setFeatureSliders((prev: any) => {
      const copy = [...prev];
      copy.unshift({
        id: uuid(),
        featureName: `Captions Animation - ${animation}`,
        color: 'rgba(255, 185, 248, 0.70)',
        startEndTime: [0, clip.duration],
        type: 'animation',
      });
      return copy;
    });
    setAnimation(animation);
  };

  // B-Roll Image item Generator
  const addABRollImageToFeatureSliders = (imageUrl: string, name: string, ratio: number) => {
    let endTime = playerProgress + 7;
    if (endTime > trimValues[1]) endTime = trimValues[1];
    setFeatureSliders((prev: any) => {
      const copy = [...prev];
      copy.push({
        id: uuid(),
        featureName: `B-Roll Image - ${name}`,
        color: getRandomColor(),
        startEndTime: [playerProgress, endTime],
        type: 'bRollImage',
        imageUrl: imageUrl,
        mobileDymension: {
          x: 0,
          y: (VIDEO_HEIGHT - MOBILE_VIDEO_WIDTH / ratio) / 2,
          height: MOBILE_VIDEO_WIDTH / ratio,
          width: MOBILE_VIDEO_WIDTH,
          ratio: ratio
        },
        desktopDymension: {
          x: (VIDEO_WIDTH - VIDEO_HEIGHT * ratio) / 2,
          y: 0,
          height: VIDEO_HEIGHT,
          width: VIDEO_HEIGHT * ratio,
          ratio: ratio
        }
      },);
      return copy;
    });
  };

  const getXPositionAssetDesktop = (position: string, ratio: number, height: number, width: number) => {
    const rightCoordinate = VIDEO_WIDTH - width - 20;

    switch (position) {
      case 'top-left':
        return 20;
      case 'bottom-left':
        return 20;
      case 'top-right':
        return rightCoordinate
      case 'bottom-right':
        return rightCoordinate

      default:
        return 20;
    }
  }

  const getYPositionAssetDesktop = (position: string, ratio: number, height: number, width: number) => {
    const bottomCoordinate = VIDEO_HEIGHT - height - 20;

    switch (position) {
      case 'top-left':
        return 20;
      case 'bottom-left':
        return bottomCoordinate;
      case 'top-right':
        return 20
      case 'bottom-right':
        return bottomCoordinate

      default:
        return 20;
    }
  }

  const getXPositionAssetMobile = (position: string, ratio: number, height: number, width: number) => {
    const rightCoordinate = MOBILE_VIDEO_WIDTH - width - 20;

    switch (position) {
      case 'top-left':
        return 20;
      case 'bottom-left':
        return 20;
      case 'top-right':
        return rightCoordinate
      case 'bottom-right':
        return rightCoordinate

      default:
        return 20;
    }
  }

  const getYPositionAssetMobile = (position: string, ratio: number, height: number, width: number) => {
    const bottomCoordinate = VIDEO_HEIGHT - height - 20;

    switch (position) {
      case 'top-left':
        return 20;
      case 'bottom-left':
        return bottomCoordinate;
      case 'top-right':
        return 20
      case 'bottom-right':
        return bottomCoordinate

      default:
        return 20;
    }
  }


  const addWatermarkToFeatureSliders = (imageUrl: string, ratio: number, position: string) => {
    const DEFAULT_HEIGHT = 40;
    const DEFAULT_WIDTH = DEFAULT_HEIGHT * ratio;
    setFeatureSliders((prev: any) => {
      const copy = [...prev];

      if (copy.find((slider: any) => slider.type === 'watermark')) {
        const updatedCopy = copy.map(slider => {
          if (slider.type === 'watermark') {
            return {
              ...slider,
              imageUrl: imageUrl,
              id: uuid(),
              position,
              mobileDymension: {
                x: getXPositionAssetMobile(position, ratio, DEFAULT_HEIGHT, DEFAULT_WIDTH),
                y: getYPositionAssetMobile(position, ratio, DEFAULT_HEIGHT, DEFAULT_WIDTH),
                height: DEFAULT_HEIGHT,
                width: DEFAULT_WIDTH,
                ratio: ratio
              },
              desktopDymension: {
                x: getXPositionAssetDesktop(position, ratio, DEFAULT_HEIGHT, DEFAULT_WIDTH),
                y: getYPositionAssetDesktop(position, ratio, DEFAULT_HEIGHT, DEFAULT_WIDTH),
                height: DEFAULT_HEIGHT,
                width: DEFAULT_WIDTH,
                ratio: ratio
              }
            };
          }
          return slider;
        });

        return updatedCopy;
      } else {
        copy.push({
          id: uuid(),
          featureName: 'Watermark',
          color: getRandomColor(),
          startEndTime: [0, clip.duration],
          type: 'watermark',
          imageUrl: imageUrl,
          position,
          mobileDymension: {
            x: getXPositionAssetMobile(position, ratio, DEFAULT_HEIGHT, DEFAULT_WIDTH),
            y: getYPositionAssetMobile(position, ratio, DEFAULT_HEIGHT, DEFAULT_WIDTH),
            height: DEFAULT_HEIGHT,
            width: DEFAULT_WIDTH,
            ratio: ratio
          },
          desktopDymension: {
            x: getXPositionAssetDesktop(position, ratio, DEFAULT_HEIGHT, DEFAULT_WIDTH),
            y: getYPositionAssetDesktop(position, ratio, DEFAULT_HEIGHT, DEFAULT_WIDTH),
            height: DEFAULT_HEIGHT,
            width: DEFAULT_WIDTH,
            ratio: ratio
          }
        },);
        return copy;
      }

    });

  };

  const handleSelectNoneWatermark = () => {
    setFeatureSliders((prev: any) => {
      const copy = [...prev];
      const filtered = copy.filter(slider => slider?.type !== 'watermark');

      return filtered;
    });
  }

  const addASocialLogoToFeatureSliders = (text: string, name: string, ratio: number) => {
    let endTime = playerProgress + 7;
    if (endTime > trimValues[1]) endTime = trimValues[1];
    setFeatureSliders((prev: any) => {
      const copy = [...prev];
      copy.push({
        id: uuid(),
        featureName: `logo - ${name}`,
        startEndTime: [playerProgress, endTime],
        type: 'logo',
        logo_type: name,
        color: getRandomColor(),
        svgType: name,
        text: text,
        mobileDymension: {
          x: 0,
          y: 0,
          height: 35,
          width: 110 * ratio,
          ratio: ratio
        },
        desktopDymension: {
          x: 0,
          y: 0,
          height: 35,
          width: 120 * ratio,
          ratio: ratio
        }
      },);
      return copy;
    });
  };

  // Text item Generator
  const addTextToFeatureSliders = useCallback((textValue: string, ratio: number, fontFamily: string, fontColor: string, fontWeight: string | number, textShadow: string, fontSize: number, textTransform: string, textShadowColor: string) => {
    let endTime = playerProgress + 7;
    if (endTime > trimValues[1]) endTime = trimValues[1];
    setFeatureSliders((prev: any) => {
      const copy = [...prev];
      copy.push({
        id: uuid(),
        featureName: `Text - ${textValue}`,
        text: textValue,
        color: getRandomColor(),
        startEndTime: [playerProgress, endTime],
        fontFamily: fontFamily,
        fontColor: fontColor,
        fontSize: fontSize,
        fontWeight: fontWeight,
        textShadow: textShadow,
        textShadowColor: textShadowColor,
        textTransform: textTransform,
        type: 'text',
        mobileDymension: {
          x: 0,
          y: 0,
          height: 12,
          width: 31 * ratio,
          ratio: ratio
        },
        desktopDymension: {
          x: 0,
          y: 0,
          height: 12,
          width: 31 * ratio,
          ratio: ratio
        }
      },);
      return copy;
    });
  }, [playerProgress, trimValues]);

  // B-Roll Video item Generator
  const addABRollVideoToFeatureSliders = (videoUrl: string, name: string, duration: number, ratio: number) => {
    let endTime = playerProgress + duration;
    if (endTime > trimValues[1]) endTime = trimValues[1];
    setFeatureSliders((prev: any) => {
      const copy = [...prev];
      copy.push({
        id: uuid(),
        featureName: `B-Roll Video - ${name}`,
        color: getRandomColor(),
        startEndTime: [playerProgress, endTime],
        type: 'bRollVideo',
        videoUrl: videoUrl,
        duration: duration,
        mobileDymension: {
          x: 0,
          y: (VIDEO_HEIGHT - MOBILE_VIDEO_WIDTH / ratio) / 2,
          height: MOBILE_VIDEO_WIDTH / ratio,
          width: MOBILE_VIDEO_WIDTH,
          ratio: ratio
        },
        desktopDymension: {
          x: (VIDEO_WIDTH - VIDEO_HEIGHT * ratio) / 2,
          y: 0,
          height: VIDEO_HEIGHT,
          width: VIDEO_HEIGHT * ratio,
          ratio: ratio
        }
      },);
      return copy;
    });
  };

  const checkContentFeedVisibility = (feature: any) => {
    if (feature.startEndTime) {
      if (feature.startEndTime[0] <= playerProgress && feature.startEndTime[1] >= playerProgress) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const handleFeatureSliderTimeChange = (id: any, time: number | number[]) => {
    setFeatureSliders((currentSliders: any) =>
      currentSliders.map((slider: any) =>
        slider.id === id ? { ...slider, startEndTime: time } : slider
      )
    );
  };

  // MAIN FUNCTION TO ADJUST ANY FEATURE SLIDER
  const editFeatureSlider = useCallback((featureSliderId: number, updatedFields: any) => {
    setFeatureSliders((currentSliders: any) => {
      return currentSliders.map((slider: any) => {
        if (slider.id === featureSliderId) {
          // Merge the existing object with the updated fields
          return { ...slider, ...updatedFields };
        }
        return slider;
      });
    });
  }, []);


  const editorTutorialWatched = localStorage.getItem('editorTutorialWatched');

  const updateAnimation = useCallback((anmiationSelected: string): void => {
    setAnimation(anmiationSelected);
  }, []);

  // useEffect(() => {
  //   if (editorTutorialWatched === 'true') {
  //     setOpenTour(false);
  //   } else {
  //     setOpenTour(true);
  //   }
  // }, [editorTutorialWatched]);

  // const handleCloseTour = () => {
  //   localStorage.setItem('editorTutorialWatched', 'true');
  //   setOpenTour(false);
  // };


  const updateSentences = useCallback((updatedSentences: SubtitleSentence[]) => {
    setSentences(updatedSentences);
  }, []);

  const updateSubtitlesSettings = useCallback((updatedSentences: subtitlesSetting) => {
    setSubtitlesSettings(updatedSentences);
  }, []);

  const updateSubtitleState = (state: boolean) => {
    if (process.env.REACT_APP_DISABLE_EMOJIS !== 'true') {
      setEmojiEnabled(state);
    };
    setSubtitleState(state);
  };

  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const fullCanvasRef = useRef(null);
  const streamerCanvasRef = useRef(null);
  const streamerRoundCanvasRef = useRef(null);
  const contentCanvasRef = useRef(null);

  // const setEditorDefaultValues = (clip: IClip & Partial<IClipEdit>, template: any) => {
  //   if (clip && clip.is_favorite) {
  //     const data = {
  //       webcam_type: currentFeedScene.webcam_type,
  //       webcam_height: currentFeedScene.webcam_height,
  //       webcam_width: currentFeedScene.webcam_width,
  //       webcam_x: currentFeedScene.webcam_x_coordinate,
  //       webcam_y: currentFeedScene.webcam_y_coordinate,
  //       webcam_output_height: currentFeedScene.webcam_output_height,
  //       webcam_output_width: currentFeedScene.webcam_output_width,
  //       webcam_output_x: currentFeedScene.webcam_output_x,
  //       webcam_output_y: currentFeedScene.webcam_output_y,
  //       feed_type: currentFeedScene.feed_type,
  //       feed_height: currentFeedScene.feed_height,
  //       feed_width: currentFeedScene.feed_width,
  //       feed_x: currentFeedScene.feed_x_coordinate,
  //       feed_y: currentFeedScene.feed_y_coordinate,
  //       feed_output_height: currentFeedScene.feed_output_height,
  //       feed_output_width: currentFeedScene.feed_output_width,
  //       feed_output_x: currentFeedScene.feed_output_x,
  //       feed_output_y: currentFeedScene.feed_output_y,
  //       blurred_background: currentFeedScene.blurred_background,
  //       webcam_enabled: currentFeedScene.webcam_enabled
  //     };

  //     return data;
  //   }

  //   if (template) {
  //     return {
  //       webcam_type: template.webcam_type,
  //       webcam_height: template.webcam_height,
  //       webcam_width: template.webcam_width,
  //       webcam_x: template.webcam_x,
  //       webcam_y: template.webcam_y,
  //       webcam_output_height: template.webcam_output_height,
  //       webcam_output_width: template.webcam_output_width,
  //       webcam_output_x: template.webcam_output_x,
  //       webcam_output_y: template.webcam_output_y,
  //       feed_type: template.feed_type,
  //       feed_height: template.feed_height,
  //       feed_width: template.feed_width,
  //       feed_x: template.feed_x,
  //       feed_y: template.feed_y,
  //       feed_output_height: template.feed_output_height,
  //       feed_output_width: template.feed_output_width,
  //       feed_output_x: template.feed_output_x,
  //       feed_output_y: template.feed_output_y,
  //       blurred_background: template.blurred_background,
  //       webcam_enabled: template.webcam_enabled
  //     };
  //   }

  //   return {
  //     webcam_type: 'landscape',
  //     webcam_height: DEFAULT_WEBCAM_POSITION.height,
  //     webcam_width: DEFAULT_WEBCAM_POSITION.width,
  //     webcam_x: DEFAULT_WEBCAM_POSITION.x,
  //     webcam_y: DEFAULT_WEBCAM_POSITION.y,
  //     webcam_output_height: DEFAULT_CANVAS_WEBCAM_POSITION.height,
  //     webcam_output_width: DEFAULT_CANVAS_WEBCAM_POSITION.width,
  //     webcam_output_x: DEFAULT_CANVAS_WEBCAM_POSITION.x,
  //     webcam_output_y: DEFAULT_CANVAS_WEBCAM_POSITION.y,
  //     feed_type: 'rectangle',
  //     feed_height: DEFAULT_FEED_POSITION.height,
  //     feed_width: DEFAULT_FEED_POSITION.width,
  //     feed_x: DEFAULT_FEED_POSITION.x,
  //     feed_y: DEFAULT_FEED_POSITION.y,
  //     feed_output_height: DEFAULT_CANVAS_FEED_POSITION.height,
  //     feed_output_width: DEFAULT_CANVAS_FEED_POSITION.width,
  //     feed_output_x: DEFAULT_CANVAS_FEED_POSITION.x,
  //     feed_output_y: DEFAULT_CANVAS_FEED_POSITION.y,
  //     blurred_background: true,
  //     webcam_enabled: true,
  //   };

  // };

  // const params = setEditorDefaultValues(clip, userContext?.user?.editor_template);
  // const [editorParams] = useState<IEditorParams>(params as any);
  const [videoReady, setVideoReady] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [showOutline, setShowOutline] = useState<boolean>(true);
  const [showNotSavedModal, setShowNotSavedModal] = useState<boolean>(false);
  const [watermark_enabled, setWatermarkEnabeld] = useState<boolean>(clip.watermark_enabled ? clip.watermark_enabled : true);
  const [watermarkPosition, setWatermarkPosition] = useState<string>(clip.watermark_position ? WATERMARK_POSITION_BUTTONS[clip.watermark_position - 1].value : 'top-right');
  const [webcamRatio, setWebcamRatio] = useState<string>(convertFeedTypeToRatio(currentFeedScene.webcam_type));
  const [webcamRatioMenuItem, setWebcamRatioMenuItem] = useState<string>(convertFeedTypeToWebCamMenuItemRatio(currentFeedScene.webcam_type));
  const [blurFeed, setBlurFeed] = useState<boolean>(currentFeedScene.blurred_background);
  const [messageApi, contextHolder] = message.useMessage();
  const [savingVideo, setSavingVideo] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    setWebcamRatioMenuItem(convertFeedTypeToWebCamMenuItemRatio(currentFeedScene.webcam_type));
    setWebcamRatio(convertFeedTypeToRatio(currentFeedScene.webcam_type));
  }, [currentFeedScene.webcam_type]);

  const free_watermarks_left = userContext?.user?.watermark_free_clips_remaining ? userContext?.user?.watermark_free_clips_remaining : 0;
  const watermark_label = watermark_enabled ? 'watermark on' : 'watermark off';
  const fullHeightFeed = !currentFeedScene?.webcam_enabled || currentFeedScene.webcam_type === 'round';

  const preRenderedCanvasRef = useRef<HTMLCanvasElement>(null);
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const fromSpikesPage = location.state?.fromSpikesPage;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const fromTranscribe = location.state?.fromTranscribe;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const fromVideos = location.state?.fromVideos;

  useEffect(() => {
    const capture = () => {
      const video = document.getElementsByTagName('video')[0];
      if (video) {
        video.onloadedmetadata = () => {
          setVideoRes({ width: video.videoWidth, height: video.videoHeight });
        };
      };

      if (video && preRenderedCanvasRef.current) {
        const preRenderedContext = preRenderedCanvasRef.current.getContext('2d');
        if (preRenderedContext) {
          preRenderedContext.drawImage(video, 0, 0, video.videoWidth, video.videoHeight, 0, 0, preRenderedContext.canvas.width, preRenderedContext.canvas.height);
        }
      }
    };

    let requestId: number;
    const render = () => {
      requestId = requestAnimationFrame(render);
      capture();
    };
    render();
    return () => {
      cancelAnimationFrame(requestId);
    };

  }, [videoRes, videoReady, isPlaying, preRenderedCanvasRef]);

  useEffect(() => {
    setBlurFeed(currentFeedScene.blurred_background);
  }, [currentFeedScene.blurred_background]);

  useEffect(() => {
    const capture = () => {
      /* @ts-expect-error undefined */
      fullCanvasRef.current?.getContext('2d').drawImage(preRenderedCanvasRef.current, normalize(draggableFirst.x), normalize(draggableFirst.y), normalize(draggableFirst.width), normalize(draggableFirst.height), 0, 0, VIDEO_WIDTH, VIDEO_HEIGHT);
      /* @ts-expect-error undefined */
      contentCanvasRef.current?.getContext('2d').drawImage(preRenderedCanvasRef.current, normalize(draggableFirst.x), normalize(draggableFirst.y), normalize(draggableFirst.width), normalize(draggableFirst.height), 0, 0, contentCanvasRef.current?.getContext('2d').canvas.width, contentCanvasRef.current?.getContext('2d').canvas.height);
    };

    let requestId: number;
    const render = () => {
      requestId = requestAnimationFrame(render);
      capture();
    };
    render();

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [isPlaying, draggableFirst, videoRes, videoReady, VIDEO_HEIGHT, VIDEO_WIDTH]);

  useEffect(() => {
    const capture = () => {

      if (currentFeedScene.webcam_type === 'round') {
        /* @ts-expect-error undefined */
        streamerRoundCanvasRef.current?.getContext('2d').drawImage(preRenderedCanvasRef.current, normalize(draggableSecond.x), normalize(draggableSecond.y), normalize(draggableSecond.width), normalize(draggableSecond.height), 0, 0, streamerRoundCanvasRef.current?.getContext('2d').canvas.width, streamerRoundCanvasRef.current?.getContext('2d').canvas.height);
      }
      else {
        /* @ts-expect-error undefined */
        streamerCanvasRef.current?.getContext('2d').drawImage(preRenderedCanvasRef.current, normalize(draggableSecond.x), normalize(draggableSecond.y), normalize(draggableSecond.width), normalize(draggableSecond.height), 0, 0, streamerCanvasRef.current?.getContext('2d').canvas.width, streamerCanvasRef.current?.getContext('2d').canvas.height);
      }
    };

    let requestId: number;
    const render = () => {
      requestId = requestAnimationFrame(render);
      capture();
    };
    render();

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [isPlaying, draggableSecond, currentFeedScene?.webcam_enabled, videoRes, videoReady, VIDEO_HEIGHT, VIDEO_WIDTH]);


  useEffect(() => {
    if (clip.subtitles) {
      setLoadingSubtitles(true);
      decompressData(clip.subtitles)
        .then(originalData => {
          clip.subtitles = JSON.stringify(originalData);
          setLoadingSubtitles(false);
        })
        .catch(error => {
          console.error('Decompress error:', error);
          setLoadingSubtitles(false);
        });
    }
    if (clip.audio_decibels) {
      decompressData(clip.audio_decibels)
        .then(originalData => {
          clip.audio_decibels = originalData;
        })
        .catch(error => {
          console.error('Decompress error:', error);
        });
    }
  }, []);

  useEffect(() => {
    setInitialFeatureSlider();
  }, [clip, videoRes]);

  const setInitialFeatureSlider = async () => {
    if (clip.features) {
      const allAssets = await fetchAllAssets();

      const parsedFeatures = JSON.parse(clip.features);
      const reformattedSliders = reformatFeatureSlidersFromAPI(parsedFeatures);

      if (allAssets) {
        const filtered = reformattedSliders.filter((slider: any) => {
          // Add other asset types in future
          if (slider.type === 'watermark') {
            if (allAssets.find((asset: any) => asset.url === slider?.imageUrl)) {
              return slider;
            }
          } else {
            return slider;
          }
        })

        setFeatureSliders(filtered);
      } else {
        setFeatureSliders(reformattedSliders);
      }
    }
  }

  const fetchAllAssets = async () => {
    const response = await axios.get('/streamer/assets');
    if (response?.data) {
      return response?.data;
    }
    else return null;
  }

  /* 
    REMOVE `isFirstLoad` IF SOMETHING GOES WRONG. COMMENTED THIS FOR TESTING, BECAUSE INITIAL VALUES OF CAMERAS ARE MESSED UP AFTER SAVING
  */
  // useEffect(() => {
  //   if (!clip.is_manual_edit && isFirstLoad) {
  //     const params = setEditorDefaultValues(clip, userContext?.user?.editor_template);
  //     const { webcam_x, webcam_y, webcam_type, feed_type, feed_x, feed_y, webcam_output_x, webcam_output_y, webcam_output_height, webcam_output_width, feed_output_height, feed_output_width, feed_output_x, feed_output_y } = params;
  //     let { webcam_height, webcam_width, feed_height, feed_width } = params;

  //     if (webcam_type !== currentFeedScene.webcam_type || feed_type !== currentFeedScene.feed_type) {
  //       switch (webcamRatio) {
  //         case '1': {
  //           webcam_height = ROUND_CAMERA_CANBAS_DRAGGABLE_PARAMS.height;
  //           webcam_width = ROUND_CAMERA_CANBAS_DRAGGABLE_PARAMS.width;
  //           break;
  //         }
  //         case '0': {
  //           webcam_height = ROUND_CAMERA_CANBAS_DRAGGABLE_PARAMS.height;
  //           webcam_width = ROUND_CAMERA_CANBAS_DRAGGABLE_PARAMS.width;
  //           break;
  //         }
  //         case '1.777777777777778': {
  //           webcam_height = CAMERA_16_9_CANBAS_DRAGGABLE_PARAMS.height;
  //           webcam_width = CAMERA_16_9_CANBAS_DRAGGABLE_PARAMS.width;
  //           break;
  //         }

  //         case '0.5625': {
  //           webcam_height = CAMERA_9_16_CANBAS_DRAGGABLE_PARAMS.height;
  //           webcam_width = CAMERA_9_16_CANBAS_DRAGGABLE_PARAMS.width;
  //           break;
  //         }

  //         default: {
  //           webcam_height = CAMERA_16_9_CANBAS_DRAGGABLE_PARAMS.height;
  //           webcam_width = CAMERA_16_9_CANBAS_DRAGGABLE_PARAMS.width;
  //           break;
  //         }
  //       }

  //       switch (convertFeedTypeToRatio(currentFeedScene.feed_type)) {
  //         case '1': {
  //           feed_height = FEED_1_1_CANVAS_DRAGGABLE_PARAMS.height;
  //           feed_width = FEED_1_1_CANVAS_DRAGGABLE_PARAMS.width;
  //           break;
  //         }
  //         case '1.777777777777778': {
  //           feed_height = FEED_16_9_CANVAS_DRAGGABLE_PARAMS.height;
  //           feed_width = FEED_16_9_CANVAS_DRAGGABLE_PARAMS.width;
  //           break;
  //         }

  //         case '0.5625': {
  //           feed_height = FEED_9_16_CANVAS_DRAGGABLE_PARAMS.height;
  //           feed_width = FEED_9_16_CANVAS_DRAGGABLE_PARAMS.width;
  //           break;
  //         }

  //         case '0.8228573309388185': {
  //           feed_height = FEED_16_19_CANVAS_DRAGGABLE_PARAMS.height;
  //           feed_width = FEED_16_19_CANVAS_DRAGGABLE_PARAMS.width;
  //           break;
  //         }

  //         default: {
  //           feed_height = FEED_1_1_CANVAS_DRAGGABLE_PARAMS.height;
  //           feed_width = FEED_1_1_CANVAS_DRAGGABLE_PARAMS.width;
  //           break;
  //         }
  //       }
  //     }
  //     const faceTrackingExists = clip.face_tracking;
  //     if (isFirstLoad && videoRes) {
  //       /* Fix for face tracking. First scene had wrong feed positions. But if you save the code, after reload everything was correct */
  //       // if (!faceTrackingExists) {
  //       //   updateDraggableFirst({
  //       //     x: feed_x,
  //       //     y: feed_y,
  //       //     width: feed_width,
  //       //     height: feed_height,
  //       //   });

  //       //   updateDraggableSecond({
  //       //     x: webcam_x,
  //       //     y: webcam_y,
  //       //     width: webcam_width,
  //       //     height: webcam_height
  //       //   });

  //       //   updateDraggableThird({
  //       //     x: webcam_output_x,
  //       //     y: webcam_output_y,
  //       //     height: webcam_output_height,
  //       //     width: webcam_output_width
  //       //   });

  //       //   updateDraggableFourth({
  //       //     x: feed_output_x,
  //       //     y: feed_output_y,
  //       //     height: feed_output_height,
  //       //     width: feed_output_width
  //       //   });
  //       // }

  //       setIsFirstLoad(false);
  //     } else {
  //       /* Fix: When camera switches to 'None', content feed and camera reset to default position */
  //       updateDraggableFirst({
  //         x: draggableFirst.x,
  //         y: draggableFirst.y,
  //         width: draggableFirst.width || feed_width,
  //         height: draggableFirst.height || feed_height,
  //       });

  //       updateDraggableSecond({
  //         x: draggableSecond.x,
  //         y: draggableSecond.y,
  //         width: webcam_width,
  //         height: webcam_height
  //       });

  //       /* Fix: When camera switches to 'None' then to 9:16 it becomes full width on the preview (not on the left side) */
  //       // updateDraggableThird({
  //       //   x: webcam_output_x,q
  //       //   y: webcam_output_y,
  //       //   height: webcam_output_height,
  //       //   width: webcam_output_width
  //       // });

  //       updateDraggableFourth({
  //         x: draggableFourth.x,
  //         y: draggableFourth.y,
  //         height: draggableFourth.height || feed_output_height,
  //         width: draggableFourth.width || feed_output_width
  //       });
  //     }
  //   }
  // }, [currentFeedScene?.webcam_enabled, videoRes]);

  useEffect(() => {
    if (clip.subtitles) {
      if (clip.subtitles[0] === '[') {
        const tempParse = JSON.parse(clip.subtitles);
        updateSentences(tempParse);
      }

    }
  }, [clip.subtitles]);

  useEffect(() => {
    if (clip.subtitles_settings) {
      const tempParse = JSON.parse(clip.subtitles_settings);
      updateSubtitlesSettings(tempParse);
    }
  }, [clip.subtitles_settings]);

  const updateCameraFeedSideWork = (value: string) => {
    // const { webcam_type, feed_type } = editorParams;
    // let { webcam_height, webcam_width } = editorParams;
    let webcam_height = currentFeedScene.webcam_height;
    let webcam_width = currentFeedScene.webcam_width;

    switch (value) {
      case '0': {
        webcam_height = ROUND_CAMERA_CANBAS_DRAGGABLE_PARAMS.height;
        webcam_width = ROUND_CAMERA_CANBAS_DRAGGABLE_PARAMS.width;
        break;
      }
      case '1': {
        webcam_height = ROUND_CAMERA_CANBAS_DRAGGABLE_PARAMS.height;
        webcam_width = ROUND_CAMERA_CANBAS_DRAGGABLE_PARAMS.width;
        break;
      }
      case '1.777777777777778': {
        webcam_height = CAMERA_16_9_CANBAS_DRAGGABLE_PARAMS.height;
        webcam_width = CAMERA_16_9_CANBAS_DRAGGABLE_PARAMS.width;
        break;
      }

      case '0.5625': {
        webcam_height = CAMERA_9_16_CANBAS_DRAGGABLE_PARAMS.height;
        webcam_width = CAMERA_9_16_CANBAS_DRAGGABLE_PARAMS.width;
        break;
      }

      default: {
        webcam_height = CAMERA_16_9_CANBAS_DRAGGABLE_PARAMS.height;
        webcam_width = CAMERA_16_9_CANBAS_DRAGGABLE_PARAMS.width;
        break;
      }
    }

    let draggable2X = draggableSecond.x;
    let draggable2Y = draggableSecond.y;
    let draggable3X = draggableThird.x;
    let draggable3Y = draggableThird.y;

    // Goes out of borders on bottom
    if ((draggableSecond.y + webcam_height) > VIDEO_HEIGHT) {
      draggable2Y = VIDEO_HEIGHT - webcam_height;
    };

    // Goes out of borders on right
    if ((draggableSecond.x + webcam_height) > VIDEO_WIDTH) {
      draggable2X = VIDEO_WIDTH - webcam_height;
    };

    // Goes out of borders on bottom
    if ((draggableThird.y + webcam_height) > VIDEO_HEIGHT) {
      draggable3Y = VIDEO_HEIGHT - webcam_height;
    };

    // Goes out of borders on right
    if ((draggableThird.x + webcam_height) > VIDEO_WIDTH) {
      draggable3X = VIDEO_WIDTH - webcam_height;
    };

    updateDraggableSecond({
      x: draggable2X,
      y: draggable2Y,
      width: webcam_width,
      height: webcam_height
    });

    if (currentFeedScene.webcam_type === 'round') {
      updateDraggableThird({
        x: draggable3X,
        y: draggable3Y,
        height: webcam_height,
        width: webcam_width
      });
    }
    else {
      updateDraggableThird({
        x: draggable3X,
        y: draggable3Y,
        height: webcam_height,
        width: webcam_width
      });
    }
  };


  // useEffect(() => {
  //   const { webcam_type, feed_type } = editorParams;
  //   let { feed_height, feed_width } = editorParams;

  //   if (webcam_type !== currentFeedScene.webcam_type || feed_type !== currentFeedScene.feed_type) {
  //     switch (convertFeedTypeToRatio(currentFeedScene.feed_type)) {
  //       case '1': {
  //         feed_height = FEED_1_1_CANVAS_DRAGGABLE_PARAMS.height;
  //         feed_width = FEED_1_1_CANVAS_DRAGGABLE_PARAMS.width;
  //         break;
  //       }
  //       case '1.777777777777778': {
  //         feed_height = FEED_16_9_CANVAS_DRAGGABLE_PARAMS.height;
  //         feed_width = FEED_16_9_CANVAS_DRAGGABLE_PARAMS.width;
  //         break;
  //       }

  //       case '0.5625': {
  //         feed_height = FEED_9_16_CANVAS_DRAGGABLE_PARAMS.height;
  //         feed_width = FEED_9_16_CANVAS_DRAGGABLE_PARAMS.width;
  //         break;
  //       }

  //       case '0.8228573309388185': {
  //         feed_height = FEED_16_19_CANVAS_DRAGGABLE_PARAMS.height;
  //         feed_width = FEED_16_19_CANVAS_DRAGGABLE_PARAMS.width;
  //         break;
  //       }

  //       default: {
  //         feed_height = FEED_1_1_CANVAS_DRAGGABLE_PARAMS.height;
  //         feed_width = FEED_1_1_CANVAS_DRAGGABLE_PARAMS.width;
  //         break;
  //       }
  //     }
  //   }

  //   // Fix when switching to 16:19 the frames get almost invisible
  //   if (feed_height < 2) feed_height = DEFAULT_FEED_POSITION.height;
  //   if (feed_width < 2) feed_width = DEFAULT_FEED_POSITION.width;

  //   let draggable1X = draggableFirst.x;
  //   let draggable1Y = draggableFirst.y;
  //   let draggable4Y = draggableFourth.y;
  //   const draggable4X = draggableFourth.x;

  //   // Goes out of borders on bottom
  //   if ((draggableFirst.y + feed_height) > VIDEO_HEIGHT) {
  //     draggable1Y = VIDEO_HEIGHT - feed_height;
  //   };

  //   // Goes out of borders on right
  //   if ((draggableFirst.x + feed_width) > VIDEO_WIDTH) {
  //     draggable1X = VIDEO_WIDTH - feed_width;
  //   };

  //   if ((draggableFourth.y + feed_height) > VIDEO_HEIGHT) {
  //     draggable4Y = VIDEO_HEIGHT - feed_height;
  //   };

  //   if (!fullHeightFeed) {
  //     console.log('first');

  //     updateDraggableFirst({
  //       x: draggable1X,
  //       y: draggable1Y,
  //       width: feed_width,
  //       height: feed_height,
  //     });
  //   } else {
  //     console.log('second');
  //     updateDraggableFirst({
  //       x: draggable1X,
  //       y: draggable1Y,
  //       width: feed_width,
  //       height: feed_height,
  //     });
  //   }

  //   // updateDraggableFourth({
  //   //   x: 0,
  //   //   y: draggable4Y,
  //   //   height: feed_height,
  //   //   width: feed_width,
  //   // });
  //   updateDraggableFourth({
  //     x: draggable4X,
  //     y: draggable4Y,
  //     height: feed_height,
  //     width: feed_width,
  //   });

  // }, [convertFeedTypeToRatio(currentFeedScene.feed_type)]);

  const updateFeedTypeSideWork = (newFeedType: any) => {
    // const { webcam_type, feed_type } = editorParams;
    // let { feed_height, feed_width } = editorParams;

    let feed_height = currentFeedScene.feed_height;
    let feed_width = currentFeedScene.feed_width;

    switch (convertFeedTypeToRatio(newFeedType)) {
      case '1': {
        feed_height = FEED_1_1_CANVAS_DRAGGABLE_PARAMS.height;
        feed_width = FEED_1_1_CANVAS_DRAGGABLE_PARAMS.width;
        break;
      }
      case '1.777777777777778': {
        feed_height = FEED_16_9_CANVAS_DRAGGABLE_PARAMS.height;
        feed_width = FEED_16_9_CANVAS_DRAGGABLE_PARAMS.width;
        break;
      }

      case '0.5625': {
        feed_height = FEED_9_16_CANVAS_DRAGGABLE_PARAMS.height;
        feed_width = FEED_9_16_CANVAS_DRAGGABLE_PARAMS.width;
        break;
      }

      case '0.8228573309388185': {
        feed_height = FEED_16_19_CANVAS_DRAGGABLE_PARAMS.height;
        feed_width = FEED_16_19_CANVAS_DRAGGABLE_PARAMS.width;
        break;
      }

      default: {
        feed_height = FEED_1_1_CANVAS_DRAGGABLE_PARAMS.height;
        feed_width = FEED_1_1_CANVAS_DRAGGABLE_PARAMS.width;
        break;
      }
    }

    // Fix when switching to 16:19 the frames get almost invisible
    if (feed_height < 2) feed_height = DEFAULT_FEED_POSITION.height;
    if (feed_width < 2) feed_width = DEFAULT_FEED_POSITION.width;

    let draggable1X = draggableFirst.x;
    let draggable1Y = draggableFirst.y;
    let draggable4Y = draggableFourth.y;
    const draggable4X = draggableFourth.x;

    // Goes out of borders on bottom
    if ((draggableFirst.y + feed_height) > VIDEO_HEIGHT) {
      draggable1Y = VIDEO_HEIGHT - feed_height;
    };

    // Goes out of borders on right
    if ((draggableFirst.x + feed_width) > VIDEO_WIDTH) {
      draggable1X = VIDEO_WIDTH - feed_width;
    };

    if ((draggableFourth.y + feed_height) > VIDEO_HEIGHT) {
      draggable4Y = VIDEO_HEIGHT - feed_height;
    };

    if (!fullHeightFeed) {
      updateDraggableFirst({
        x: draggable1X,
        y: draggable1Y,
        width: feed_width,
        height: feed_height,
      });
    } else {
      updateDraggableFirst({
        x: draggable1X,
        y: draggable1Y,
        width: feed_width,
        height: feed_height,
      });
    }

    // updateDraggableFourth({
    //   x: 0,
    //   y: draggable4Y,
    //   height: feed_height,
    //   width: feed_width,
    // });
    updateDraggableFourth({
      x: draggable4X,
      y: draggable4Y,
      height: feed_height,
      width: feed_width,
    });
  };

  const styles = useMemo(() => {
    return {
      modal: {
        borderRadius: 24,
        overflow: 'hidden',
        position: 'relative',
        border: '1px solid #FFF',
        borderColor: '#00eff833',
        background: 'rgba(255, 255, 255, 0.06)',
      },
      modalBody: {
        background: 'rgba(255, 255, 255, 0.06)',
        backdropFilter: 'blur(20px)',
      },
      modalMask: {
        backdropFilter: 'blur(5px)',
      },
      closeIcon: {
        position: 'absolute',
        right: 20,
        top: 10,
      },
      modalContainer: {
        width: '100%',
        height: '100%'
      },
      modalHeader: {
        marginBottom: 15,
        display: 'flex',
        marginTop: 5,
        flexDirection: 'column',
        alignItems: 'start',
      },
      toggleButtonContainer: {
        width: 200,
      },
      playerContainer: {
        position: 'relative',
        height: VIDEO_HEIGHT,
        width: VIDEO_WIDTH
      },

      loaderContainer: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zindex: 1
      },
      mobileViewContainer: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
      },
      mobileView: {
        width: MOBILE_VIDEO_WIDTH,
        height: VIDEO_HEIGHT,
        backgroundColor: 'black',
        overflow: 'hidden',
        position: 'relative',
        borderRadius: 0
      },

      feedCanvas: {
        overflow: 'hidden',
        position: 'absolute',
        filter: blurFeed ? 'blur(5px)' : 'blur(30px)',
        top: 1,
      },
      cameraCanvas: {
        width: 248.06,
        height: 140,
        overflow: 'hidden',
        position: 'absolute',
        top: 1,
      },
      cameraRoundCanvas: {
        width: 90,
        height: 90,
        overflow: 'hidden',
        position: 'absolute',
        borderRadius: '50%',

      },
      dividerContainer: {
        padding: '0px 8px',
      },
      dividerContainer_bottom: {
        padding: '0px 8px',
        marginTop: 80
      },
      divider: {
        margin: '5px 0',
        background: 'rgba(103, 255, 204, 0.27)',
        height: 2,
        borderRadius: 8,
      },
      dividerVertical: {
        margin: '5px 0',
        background: 'rgba(103, 255, 204, 0.27)',
        height: 441,
        borderRadius: 8,
        width: 2,
        position: 'relative',
        marginTop: 0,
      },
      modalFooter: {
        marginBottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
      },
      spikeTimeRange: {
        color: COLORS.LABEL,
        fontWeight: 200,
      },
      watermarkButton: {
        width: 173,
        height: 52,
        fontSize: '10',
        fontWeight: 600,
        border: '2px solid rgb(25, 27, 45)',
        letterSpacing: 'normal',
        color: '#75EAB5',
        backgroundColor: 'black'
      },

      watermarkButton_empty: {
        width: 173,
        height: 52,
        fontSize: 9,
        fontWeight: 'bold',
        border: '2px solid rgb(25, 27, 45)',
        letterSpacing: 'normal',
        color: '#75EAB5',
        textAligh: 'left',
        backgroundColor: 'black'
      },
      contentFeedTitle: {
        width: 173,
        height: 36,
        fontSize: '11',
        fontWeight: 'bold',
        letterSpacing: 'normal',
        color: '#67FFCC',
        backgroundColor: 'black',
        border: '2px dashed #05F29B',
      },
      blurredBackgroundButton: {
        filter: blurFeed ? 'blur(1px)' : 'blur(0px)',
        fontWeight: 'bold',
      },
      saveTemplateButtonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
      },
      saveTemplateButton: {
        width: '200px',
        marginTop: '32px',
        fontWeight: 300,
        borderRadius: 4,
        height: 56,
        fontSize: 20,
        textTransform: 'none'
      },
      watermarkButtons: {
        marginTop: '-17px',
      },
      areaTitle: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        color: '#A0A0A0',
        marginBottom: '8px'
      },
      topControllsContainer: {
        padding: '20px 0',
        marginBottom: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      videoInfoContainer: {
        display: isTablet ? 'none' : 'flex',
        alignItems: isSmallScreen ? 'flex-start' : 'center',
        marginTop: isSmallScreen ? '-68px' : '-60px',
        marginBottom: isSmallScreen ? '13px' : '28px',
        width: 'fit-content',
        flexDirection: isSmallScreen ? 'column' : 'row',
      },
      videoName: {
        fontSize: '20px',
        lineHeight: '28px',
        fontWeight: 400,
      },
      videoDate: {
        fontSize: '18px',
        lineHeight: '28px',
        fontWeight: 400,
        color: COLORS.LIGHT_GRAY_TEXT
      },
      cameraText: {
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '28px',
        color: COLORS.PINK,
        marginRight: '16px'
      },
      contentText: {
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '28px',
        color: COLORS.PRIMARY,
        marginRight: '16px',
        marginLeft: '24px'
      },
    } as const;
  }, [currentFeedScene?.webcam_enabled, currentFeedScene?.webcam_type, blurFeed, VIDEO_HEIGHT, VIDEO_WIDTH, MOBILE_VIDEO_WIDTH, isSmallScreen, isTablet]);

  const formatFeatureSlidersForAPI = (sliders: any) => {
    return sliders.map((slider: any) => {
      if (slider.mobileDymension && slider.desktopDymension) return {
        ...slider,
        mobileDymension: {
          x: normalizeCanvas(slider.mobileDymension.x),
          y: normalizeCanvas(slider.mobileDymension.y),
          height: normalizeCanvas(slider.mobileDymension.height),
          width: normalizeCanvas(slider.mobileDymension.width),
          ratio: slider.ratio
        },
        desktopDymension: {
          x: normalize(slider.desktopDymension.x),
          y: normalize(slider.desktopDymension.y),
          height: normalize(slider.desktopDymension.height),
          width: normalize(slider.desktopDymension.width),
          ratio: slider.ratio
        },
      };
      else return { ...slider };
    });
  };
  const reformatFeatureSlidersFromAPI = (sliders: any) => {
    return sliders.map((slider: any) => {
      if (slider.mobileDymension && slider.desktopDymension) return {
        ...slider,
        mobileDymension: {
          x: downsizeCanvas(slider.mobileDymension.x),
          y: downsizeCanvas(slider.mobileDymension.y),
          height: downsizeCanvas(slider.mobileDymension.height),
          width: downsizeCanvas(slider.mobileDymension.width),
          ratio: slider.ratio || downsizeCanvas(slider.mobileDymension.width) / downsizeCanvas(slider.mobileDymension.height)
        },
        desktopDymension: {
          x: downsize(slider.desktopDymension.x),
          y: downsize(slider.desktopDymension.y),
          height: downsize(slider.desktopDymension.height),
          width: downsize(slider.desktopDymension.width),
          ratio: slider.ratio || downsize(slider.desktopDymension.width) / downsize(slider.desktopDymension.height)
        },
      };
      else return { ...slider };
    });
  };

  const copyTimestamp = () => {
    navigator.clipboard.writeText(`https://www.twitch.tv/videos/${platformStreamId}?t=${secondsToTime(clip.start_time).replace(':', 'h').replace(':', 'm')}s`);
    message.success({
      content: 'Timestamp copied!', style: {
        color: COLORS.BLACK,
      }
    });
  };

  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  const prepareFeedScenesForApi = (allFeeds: IFeedScene[]) => {
    const normalizedFeedScenes = allFeeds.map(feed => ({
      ...feed,
      webcam_x_coordinate: normalize(feed.webcam_x_coordinate || 0),
      webcam_y_coordinate: normalize(feed.webcam_y_coordinate || 0),
      webcam_width: normalize(feed.webcam_width || 0),
      webcam_height: normalize(feed.webcam_height || 0),

      webcam_output_x: normalizeCanvas(feed.webcam_output_x || 0),
      webcam_output_y: normalizeCanvas(feed.webcam_output_y || 0),
      webcam_output_width: normalizeCanvas(feed.webcam_output_width || 0),
      webcam_output_height: normalizeCanvas(feed.webcam_output_height || 0),

      feed_x_coordinate: normalize(feed.feed_x_coordinate || 0),
      feed_y_coordinate: normalize(feed.feed_y_coordinate || 0),
      feed_width: normalize(feed.feed_width || 0),
      feed_height: normalize(feed.feed_height || 0),

      feed_output_x: normalizeCanvas(feed.feed_output_x || 0),
      feed_output_y: normalizeCanvas(feed.feed_output_y || 0),
      feed_output_width: normalizeCanvas(feed.feed_output_width || 0),
      feed_output_height: normalizeCanvas(feed.feed_output_height || 0),
      hidden_scene: feed.hidden_scene || false,
      blurred_background: feed.blurred_background || true,
    }));
    return JSON.stringify(normalizedFeedScenes);
  };

  const saveButtonDisabled = !videoRes || savingVideo;

  const save = async (withSaveTemplate?: boolean) => {
    setShowSaveModal(false);
    if (saveButtonDisabled) return null;
    setSavingVideo(true);

    const subtitlesSettingsCopy = typeof subtitlesSettings === 'object' ? { ...subtitlesSettings } : subtitlesSettings;
    if (typeof subtitlesSettingsCopy === 'object') {
      subtitlesSettingsCopy.captions_animation_type = animation
    }

    const compressedSubtitles = await compressData(sentences);
    const data: any = {
      id: clip.id,
      start_time_in_event: trimValues[0],
      end_time_in_event: trimValues[1],
      webcam_enabled: Boolean(currentFeedScene?.webcam_enabled), // REMOVE
      webcam_x_coordinate: normalize(Math.max(draggableSecond.x, 0)), // REMOVE
      webcam_y_coordinate: normalize(Math.max(draggableSecond.y, 0)), // REMOVE
      webcam_height: normalize(draggableSecond.height), // REMOVE
      webcam_width: normalize(draggableSecond.width), // REMOVE
      webcam_type: currentFeedScene.webcam_type, // REMOVE
      webcam_position: 1, // REMOVE
      feed_x_coordinate: normalize(Math.max(draggableFirst.x, 0)), // REMOVE
      feed_y_coordinate: normalize(Math.max(draggableFirst.y, 0)), // REMOVE
      feed_height: normalize(draggableFirst.height), // REMOVE
      feed_width: normalize(draggableFirst.width), // REMOVE
      watermark_position: ROUND_CAMERA_POSITION_BUTTONS.findIndex(btn => btn.value === watermarkPosition) + 1,
      watermark_enabled: false,
      webcam_output_x: normalizeCanvas(draggableThird.x), // REMOVE
      webcam_output_y: normalizeCanvas(draggableThird.y), // REMOVE
      webcam_output_height: normalizeCanvas(draggableThird.height), // REMOVE
      webcam_output_width: normalizeCanvas(draggableThird.width), // REMOVE
      feed_output_x: normalizeCanvas(draggableFourth.x), // REMOVE
      feed_output_y: normalizeCanvas(draggableFourth.y), // REMOVE
      feed_output_height: normalizeCanvas(draggableFourth.height), // REMOVE
      feed_output_width: normalizeCanvas(draggableFourth.width), // REMOVE
      blurred_background: blurFeed || true,
      feed_type: currentFeedScene.feed_type, // REMOVE
      subtitles: compressedSubtitles,
      subtitles_enabled: subtitleState,
      subtitles_settings: JSON.stringify(subtitlesSettingsCopy),
      animation: animation,
      emojis_enabled: emojiEnabled !== undefined ? emojiEnabled : true,
      features: JSON.stringify(formatFeatureSlidersForAPI(featureSliders)),
      edit_properties: prepareFeedScenesForApi(allFeedScenes)
    };

    messageApi.success('Editing in Progress...', 3);

    data.feed_type = data.feed_type === null ? 'landscape' : data.feed_type;
    data.webcam_type = data.webcam_type === null ? 'round' : data.webcam_type;
    const response = await axios.put('/streamer/edit-clip', data).catch((error: { response: any; }) => {
      if (error.response) {
        console.log(error.response);
      }
    });

    if (withSaveTemplate) {
      saveTemplate();
    }

    await delay(3000);
    setSavingVideo(false);
    if (!watermark_enabled) {
      const user = userContext!.user;
      user!.watermark_free_clips_remaining = free_watermarks_left - 1;
      userContext?.setUser(user!);
    }
    localStorage.setItem('lastEditedClipId', clip?.id);
    goBack(true);
  };


  const loadTemplate = async () => {
    message.success({
      content: 'Template Reloaded', style: {
        color: COLORS.BLACK,
      }
    });

    const template = userContext?.user?.editor_template;
    if (template == null) {
      return;
    }

    updateDraggableFirst({
      x: template.feed_x,
      y: template.feed_y,
      width: template.feed_width,
      height: template.feed_height,
    });

    updateDraggableSecond({
      x: template.webcam_x,
      y: template.webcam_y,
      width: template.webcam_width,
      height: template.webcam_height,
    });

    updateDraggableThird({
      x: template.webcam_output_x,
      y: template.webcam_output_y,
      width: template.webcam_output_width,
      height: template.webcam_output_height,
    });

    updateDraggableFourth({
      x: template.feed_output_x,
      y: template.feed_output_y,
      width: template.feed_output_width,
      height: template.feed_output_height,
    });

    updateWebcamEnabled(template.webcam_enabled);
    setBlurFeed(template.blurred_background);
    updateFeedType(template.feed_type, true);
    updateWebcamType(template.webcam_type);
    setWebcamRatio(convertFeedTypeToRatio(template.webcam_type));
    setWebcamRatioMenuItem(convertFeedTypeToWebCamMenuItemRatio(template.webcam_type));
  };

  const saveTemplate = async () => {
    message.success({
      content: 'Template saved!', style: {
        color: COLORS.BLACK,
      }
    });

    const subtitlesSettingsCopy = typeof subtitlesSettings === 'object' ? { ...subtitlesSettings } : subtitlesSettings;
    if (typeof subtitlesSettingsCopy === 'object') {
      subtitlesSettingsCopy.captions_animation_type = animation;
      subtitlesSettingsCopy.wordLevel = JSON.stringify(!JSON.parse(subtitlesSettingsCopy.wordLevel)); // Temporary fix. We have inverted value. `wordLevel` is `false` when word level is selected
    }

    const template: any = {
      blurred_background: blurFeed,
      webcam_enabled: currentFeedScene?.webcam_enabled, // TODO: Update template functionality to supoort array of `edit_properties`
      feed_height: draggableFirst.height,
      feed_output_height: draggableFourth.height,
      feed_output_width: draggableFourth.width,
      feed_output_x: draggableFourth.x,
      feed_output_y: draggableFourth.y,
      feed_type: currentFeedScene.feed_type,
      feed_width: draggableFirst.width,
      feed_x: draggableFirst.x,
      feed_y: draggableFirst.y,
      webcam_height: draggableSecond.height,
      webcam_output_height: draggableThird.height,
      webcam_output_width: draggableThird.width,
      webcam_output_x: draggableThird.x,
      webcam_output_y: draggableThird.y,
      webcam_type: currentFeedScene.webcam_type,
      webcam_width: draggableSecond.width,
      webcam_x: draggableSecond.x,
      webcam_y: draggableSecond.y,
      subtitles_settings: subtitlesSettingsCopy,
      feature_sliders: featureSliders
    };

    const data = {
      editor_template: template
    };
    const response = await axios.put('/streamer/update-user-editor-template', data).catch((error: { response: any; }) => {
      if (error.response) {
        console.log(error.response);
      }
    });

    const user = userContext!.user;
    user!.editor_template = template;
    userContext?.setUser(user!);
  };

  const close = () => {
    navigate('/spikes');
  };

  const goBack = (withState = false) => {
    if (withState && fromSpikesPage) {
      localStorage.setItem('loadingClipId', JSON.stringify(clip.id));
      navigate('/spikes/streams');
    } else if (withState && fromTranscribe) {
      localStorage.setItem('loadingClipId', JSON.stringify(clip.id));
      navigate('/spikes/transcribe');
    } else if (withState && fromVideos) {
      localStorage.setItem('loadingClipId', JSON.stringify(clip.id));
      navigate('/spikes/videos');
    }
    else {
      navigate(-1);
    }
  };

  const updateCameraFeed = async (value: string, withoutSidework?: boolean) => {
    switch (value) {
      case 'none': {
        updateWebcamEnabled(false);
        break;
      }
      case '0': {
        updateWebcamEnabled(true);
        updateWebcamType('round');
        // setWebcamRatio('1');
        // setWebcamRatioMenuItem('0');
        break;
      }
      default: {
        updateWebcamEnabled(true);
        updateWebcamType(convertRatioToFeedType(value));
        // setWebcamRatio(value);
        // setWebcamRatioMenuItem(value);
        break;
      }
    };

    if (!withoutSidework) updateCameraFeedSideWork(value);
  };

  const updateContentFeed = async (value: string) => {
    switch (value) {
      case '1': {
        updateFeedType('square');
        break;
      }
      case '1.777777777777778': {
        updateFeedType('landscape');
        break;
      }
      case '0.5625': {
        updateFeedType('portrait');
        break;
      }
      case '0.8228573309388185': {
        updateFeedType('rectangle');
      }
    }
    // setFeedRatio(value);
  };

  const stepStyle = {
    backgroundColor: '#e8e8e8',
    color: '#000'
  };

  const steps = [
    {
      selector: '[data-tour="subtitles"]',
      content: 'Play with caption styles! Choose pre-made styles, fonts, sizes, and colors to make it yours 🎨',
      style: stepStyle,
    },
    {
      selector: '[data-tour="editor-controls"]',
      content: 'Manage feeds, Backgrounds and save templates. Enjoy! 🔧',
      style: stepStyle,
    },
  ];

  const handleResetFilters = useCallback(() => {
    if (clip.subtitles_settings) {
      const parsedSettings = JSON.parse(clip.subtitles_settings);
      setSubtitlesSettings(parsedSettings);
      const tempParse = JSON.parse(clip.subtitles);
      updateSentences(tempParse);
    } else {
      setSubtitlesSettings(defaultTextSettings);
      const tempParse = JSON.parse(clip.subtitles);
      updateSentences(tempParse);
    }
  }, []);
  const optimizeEditor = Boolean(clip?.duration && clip?.duration > 300);

  const playerOnPlay = useCallback(() => {
    setIsPlaying(true);
  }, []);

  const playerOnPause = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const playerOnReady = useCallback(() => {
    setVideoReady(true);
  }, []);

  const onTrimChange = useCallback((values: any) => {
    setTrimValues(values);
  }, []);

  const gifsTabEnabledForUser = getEnabledGifsForUser(userContext?.user);
  const elementsTabEnabledForUser = getEnabledElementsForUser(userContext?.user);

  const handleEditorTabChange = (tab: EditorTabNames) => {
    setActiveEditorTab(tab);
  };

  const playerProps = usePlayer({
    url: clip.url,
    startTime: 0,
    endTime: 0,
    initialTrimValues: [clip.start_time_in_event == null ? 0 : clip.start_time_in_event, clip.end_time_in_event == null ? clip?.duration && clip?.duration > 300 ? 300 : clip?.duration || 30 : clip.end_time_in_event],
    showCrop: true,
    light: false,
    onPause: playerOnPause,
    onTrimChange: onTrimChange,
  });

  const onPlayPause = (play: boolean) => {
    const endTime = 0; // Hardcode
    const startTime = 0; // Hardcode
    if (playerRef?.current && !play && endTime && playerProps.progress >= endTime) {
      (playerRef?.current as _ReactPlayerClass).seekTo(startTime, 'seconds');
    }

    playerProps.setPlaying(!play);

    if (play) {
      playerOnPause();
    } else {
      playerOnPlay();
    }
  };

  const seekTo = (value: number) => {
    if (playerRef?.current) {
      (playerRef?.current as _ReactPlayerClass).seekTo(value, 'seconds');
      setCurrentSeekedTimeOfMainPlayer && setCurrentSeekedTimeOfMainPlayer(value);
    }
  };

  const onSeek = (value: number) => {
    const trimValues = trimValuesRef.current;
    const showCrop = true;
    if (showCrop) {
      if (value <= (Array.isArray(trimValues) ? trimValues[0] : playerProps.trimValues[0])) {
        seekTo(trimValues[0]);
        return;
      } else if (value >= (Array.isArray(trimValues) ? trimValues[1] : playerProps.trimValues[1])) {
        seekTo((Array.isArray(trimValues) ? trimValues[1] : playerProps.trimValues[1]));
        return;
      }
    }
    seekTo(value);
  };

  const handleTrimmingBarChange = (values: [number, number]) => {
    if (values[0] + MAX_TRIMMING_RANGE <= values[1]) {
      seekTo(values[0]);
      setTrimValues([values[0], values[0] + MAX_TRIMMING_RANGE]);
    } else {
      // If only END trimming point is changed, seek to the end trimm of the video
      if (trimValues[1] !== values[1] && trimValues[0] === values[0]) {
        seekTo(values[0]);
        // seekTo(values[1]); // Fix bug when values[0] === 0 and cursor is moving to a middle of the clip on the END trimmer change
      } else {
        seekTo(values[0]);
      }
      setTrimValues(values);
    }
  };

  // NOTE: If this function has bugs => start using the brand new `addSceneByBreakpoints` function
  const handleRemoveSilentMoments = () => {
    if (clip.silent_moments) {
      const parsed = JSON.parse(clip.silent_moments);
      const breakpointsArray: any[] = [];

      if (Array.isArray(parsed)) {
        if (parsed.length > 0) {
          parsed.forEach(arr => {
            if (Array.isArray(arr)) {
              arr.forEach(time => {
                breakpointsArray.push(time / 1000);
              })
            }
          })

          parsed.forEach(arr => {
            if (Array.isArray(arr)) {
              handleHideFeedScene(arr[0] / 1000);
            }
          })
        }
      }

      if (breakpointsArray.length > 0) {
        handleSplitSilentMoments(breakpointsArray)
      }
    }
  };

  const checkIfAnyPropertyWasChanged = () => {
    const templateWatermark = userContext?.user?.editor_template?.feature_sliders?.find(slider => slider.type === 'watermark');
    const editorWatermark = featureSliders.find((slider: any) => slider.type === 'watermark');

    if (parsedSubtitleSettings?.fontFamily !== parsedUpdatedSubtitleSettings?.fontFamily) return true;
    if (parsedSubtitleSettings?.fontSize !== parsedUpdatedSubtitleSettings?.fontSize) return true;
    if (parsedSubtitleSettings?.color !== parsedUpdatedSubtitleSettings?.color) return true;
    if (parsedSubtitleSettings?.alignItems !== parsedUpdatedSubtitleSettings?.alignItems) return true;
    if (parsedSubtitleSettings?.textShadow !== parsedUpdatedSubtitleSettings?.textShadow) return true;
    if (parsedSubtitleSettings?.background !== parsedUpdatedSubtitleSettings?.background) return true;
    if (parsedSubtitleSettings?.fontWeight !== parsedUpdatedSubtitleSettings?.fontWeight) return true;
    if (parsedSubtitleSettings?.wordLevel !== parsedUpdatedSubtitleSettings?.wordLevel) return true;
    if (!_.isEqual(editorWatermark, templateWatermark)) return true;
    if (clip?.captions_animation_type !== animation) return true;
    return false;
  }

  const handleClickSaveButton = () => {
    if (checkIfAnyPropertyWasChanged()) {
      setShowSaveModal(true);
    } else {
      save();
    }
  };

  const handleDeleteAllScenes = () => {
    const fullDuration = currentFeedScene.end_time_in_event;

    const copyScene = { ...currentFeedScene };
    copyScene.start_time_in_event = 0;
    copyScene.end_time_in_event = fullDuration;
    setAllFeedScenes([copyScene]);
  }

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
    return (
      <components.DropdownIndicator {...props}>
        <DropdownIndicatorIcon />
      </components.DropdownIndicator>
    );
  };

  if (!currentFeedScene) return null;

  const parsedSubtitleSettings: ISubtitlesSettings = clip?.subtitles_settings !== null ? JSON.parse(clip?.subtitles_settings || '') : null;
  const parsedUpdatedSubtitleSettings = typeof subtitlesSettings === 'object' ? subtitlesSettings : {} as ISubtitlesSettings;

  const isVerticalVideo = useMemo(() => Boolean(VIDEO_HEIGHT > VIDEO_WIDTH), [VIDEO_HEIGHT, VIDEO_WIDTH]);

  return (
    <div className="editor-page-container">
      <div className="editor-header">
        <Link className="editor-header__logo" to='/'><LogoIcon style={{ fill: 'white' }} /></Link>
        <div className="editor-header__buttons">
          {showGoProButton(userContext?.user) &&
            <div onClick={() => navigate('/subscriptions')} className="editor-header__go-pro editor-header__button">
              <span>Go Pro</span>
              <SmallPlayIcon />
            </div>
          }
          <div onClick={saveButtonDisabled ? () => null : () => handleClickSaveButton()} className={`editor-header__save editor-header__button ${saveButtonDisabled && 'disabled'}`}>
            <span>Save</span>
            {savingVideo ? <Spin size="small" /> : <DownloadIcon />}
          </div>
        </div>
      </div>
      <Modal
        isOpen={showSplitSceneModal}
        className="Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseSplitSceneModal}
      >
        <div className="glass-modal">
          <span onClick={handleCloseSplitSceneModal} className="icon-close">X</span>
          <h1>Scene Segmentation</h1>
          <p>You have the ability to segment your video into distinct scenes. Each scene can be customized independently, allowing you to adjust elements within them, such as their content and positioning.</p>
          <p>Furthermore, you can remove scenes from your video simply by clicking:</p>

          <div className="scene-breakpoints demo">
            <div style={{ width: '30%' }} className='scene-breakpoints__pill' />
            <div style={{ width: '40%' }} className='scene-breakpoints__pill active' />
            <div style={{ width: '20%' }} className='scene-breakpoints__pill' />
            <div style={{ width: '10%' }} className='scene-breakpoints__pill' />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showSaveModal}
        className="Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setShowSaveModal(false)}
      >
        <div className="glass-modal save-modal">
          <span onClick={() => setShowSaveModal(false)} className="icon-close">X</span>
          <h1>Edit Clip</h1>
          <p>We see that you changed some elements of this video, would you like us to update the template for your auto-edited clips?</p>
          <div className="diff-list">
            <FieldDifference label='Font' oldValue={parsedSubtitleSettings?.fontFamily} newValue={parsedUpdatedSubtitleSettings?.fontFamily} />
            <FieldDifference label='Font Size' oldValue={parsedSubtitleSettings?.fontSize} newValue={parsedUpdatedSubtitleSettings?.fontSize} />
            <FieldDifference label='Font Color' oldValue={parsedSubtitleSettings?.color} newValue={parsedUpdatedSubtitleSettings?.color} />
            <FieldDifference label='Caption Position' oldValue={parsedSubtitleSettings?.alignItems} newValue={parsedUpdatedSubtitleSettings?.alignItems} />
            <FieldDifference label='Stroke' oldValue={parsedSubtitleSettings?.textShadow} newValue={parsedUpdatedSubtitleSettings?.textShadow} />
            <FieldDifference label='Stroke Color' oldValue={parsedSubtitleSettings?.background} newValue={parsedUpdatedSubtitleSettings?.background} />
            <FieldDifference label='Font Weight' oldValue={parsedSubtitleSettings?.fontWeight} newValue={parsedUpdatedSubtitleSettings?.fontWeight} />
            <FieldDifference label='Caption Grouping' oldValue={parsedSubtitleSettings?.wordLevel ? 'Word' : 'Group'} newValue={parsedUpdatedSubtitleSettings?.wordLevel ? 'Word' : 'Group'} />
            <FieldDifference label='Animation' oldValue={clip?.captions_animation_type} newValue={animation} />
            <FieldDifference label='Watermark' oldValue='Old' newValue='New' />
          </div>
          <div className="buttons">
            <div onClick={() => save()} style={{ flex: 1 }} className="cancel-btn">No Thanks</div>
            <div onClick={() => save(true)} style={{ flex: 1 }} className="confirm-btn">Yes</div>
          </div>
        </div>
      </Modal>
      {/* <Tour
        rounded={7}
        className="tour-wrapper"
        accentColor="#303030"
        steps={steps}
        isOpen={openTour}
        closeWithMask={false}
        onRequestClose={handleCloseTour} /> */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <>
        {contextHolder}
        <canvas width={videoRes?.width || 1920} height={videoRes?.height || 1080} ref={preRenderedCanvasRef} style={{ display: 'none' }}></canvas>
        <div className="editor-wrapper">
          <div className="left-panel-container">
            {/* Left part goes here */}
            <div className='subtitles-panel-container' style={{ display: 'flex' }}>
              <EditorNavigator activeEditorTab={activeEditorTab} handleTabChange={handleEditorTabChange} />
              <div data-tour='subtitles' style={{ minHeight: '100%', display: 'flex', flexDirection: 'column', minWidth: '347px', width: '347px' }}>
                <SubtitlesPanel
                  trimValues={trimValues}
                  setTrimValues={setTrimValues}
                  updateSubtitlesSettings={updateSubtitlesSettings}
                  subtitlesSettings={subtitlesSettings}
                  sentences={sentences}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  playerRef={optimizeEditor ? null : playerRef}
                  updateSentences={updateSentences}
                  resetFilters={handleResetFilters}
                  optimizeEditor={optimizeEditor}
                  emojiEnabled={emojiEnabled}
                  loadingSubtitles={loadingSubtitles}
                  activeEditorTab={activeEditorTab}
                  subtitleState={subtitleState}
                  allFeedScenes={allFeedScenes}
                  updateFunction={updateAnimation}
                  handleChooseBRollImage={handleChooseBRollImage}
                  handleChooseBRollVideo={handleChooseBRollVideo}
                  handleChooseGifs={handleChooseGifs}
                  handleAddTextToFeatureSliders={addTextToFeatureSliders}
                  featureSliders={featureSliders}
                  editFeatureSlider={editFeatureSlider}
                  handleDeleteFeatureSlider={handleDeleteFeatureSlider}
                  handleChooseSocialLogo={handleChooseSocialLogo}
                  setEmojiEnabled={setEmojiEnabled}
                  setSubtitleState={setSubtitleState}
                  onSeek={onSeek}
                  clip={clip}
                  handleRemoveSilentMoments={handleRemoveSilentMoments}
                  rightToLeft={rightToLeft}
                  makeClipFromSelectedWords={makeClipFromSelectedWords}
                  hideSelectedWords={hideSelectedWords}
                  handleChooseWatermark={handleChooseWatermark}
                  handleSelectNoneWatermark={handleSelectNoneWatermark}
                />
              </div>
            </div>
          </div>
          <div ref={editorRef} className="player-and-control-container">
            {/* Whole right part goes here */}

            <div className="player-container">
              {/* Responsive player */}
              <div className="desktop-player-container">
                <div className='player-name-label'>
                  <span>Original Video</span>
                  <Tooltip overlayClassName='tooltip' title="The original video is also being edited alongside the 9:16 version.">
                    <IconInfoCircle />
                  </Tooltip>
                </div>
                <div style={styles.playerContainer}>
                  {!videoReady &&
                    <div style={styles.loaderContainer}>
                      <Image src={Loader} preview={false} width={70} style={{ borderRadius: 8 }} />
                    </div>
                  }

                  {featureSliders.map((feature: any) => (
                    <>
                      {feature.type === 'text' && <HiddenTextField textFeatureSlider={feature} type='desktop' playerWidth={VIDEO_WIDTH} isVerticalVideo={isVerticalVideo} />}
                    </>
                  ))}

                  <div style={{ visibility: videoReady ? 'visible' : 'hidden' }}></div>
                  <PlayerEditor
                    enableTimeLine
                    playerRef={playerRef}
                    url={clip.url}
                    thumbnailUrl={clip.thumbnail_url}
                    showCrop
                    disableTrim={!showOutline}
                    light={false}
                    height={VIDEO_HEIGHT}
                    onPlay={playerOnPlay}
                    onPause={playerOnPause}
                    onReady={playerOnReady}
                    trimValuesProp={trimValues}
                    setPlayerProgress={setPlayerProgress}
                    playerProps={playerProps}
                    seekTo={seekTo}
                    setPlayerLoadedSeconds={setPlayerLoadedSeconds}
                    allFeedScenes={allFeedScenes}
                  />

                  {/* Feature sliders content */}
                  {videoRes && videoReady && featureSliders.map((feature: any, featureIndex: number) => feature.desktopDymension && (feature.imageUrl || feature.videoUrl || feature.text || feature.svgType) && (
                    (checkContentFeedVisibility(feature) &&
                      <>
                        <VideoCrop
                          defaultPosition={feature.desktopDymension}
                          position={{ x: feature.desktopDymension.x, y: feature.desktopDymension.y }}
                          size={{ width: feature.desktopDymension.width, height: feature.desktopDymension.height }}
                          lockRatio={feature.type === 'text' || feature.type === 'logo' ? false : true}
                          ratio={feature.desktopDymension.ratio}
                          imageUrl={feature.imageUrl}
                          videoUrl={feature.videoUrl}
                          textFeatureSlider={feature.type === 'text' && feature}
                          svgType={feature.svgType}
                          svgText={feature.text}
                          borderColor={feature.color}
                          zIndex={featureIndex + 1}
                          videoStartEndTime={feature.startEndTime}
                          isMainPlayerPlaying={isPlaying}
                          disableDragging={feature?.position && feature?.position !== 'custom'}
                          currentSeekedTimeOfMainPlayer={currentSeekedTimeOfMainPlayer}
                          playerWidth={VIDEO_WIDTH}
                          isVerticalVideo={isVerticalVideo}
                          onDragPositionChange={(values) => {
                            updateSliderFeatureDesktopDimension(feature.id, values);
                          }}
                        />
                      </>
                    )
                  ))}
                  {videoRes && videoReady &&
                    <VideoCrop
                      defaultPosition={draggableFirst}
                      position={{ x: draggableFirst.x, y: draggableFirst.y }}
                      size={{ width: draggableFirst.width, height: draggableFirst.height }}
                      lockRatio={true}
                      isVerticalVideo={isVerticalVideo}
                      ratio={parseFloat(convertFeedTypeToRatio(currentFeedScene.feed_type))}
                      borderColor='#05F29B'
                      onDragPositionChange={(values) => {
                        updateDraggableFirst(values);
                      }}
                    />
                  }
                  {videoRes && videoReady && currentFeedScene?.webcam_enabled &&
                    <VideoCrop
                      defaultPosition={draggableSecond}
                      position={{ x: draggableSecond.x, y: draggableSecond.y }}
                      size={{ width: draggableSecond.width, height: draggableSecond.height }}
                      lockRatio={true}
                      ratio={parseFloat(webcamRatio)}
                      borderColor='#FF2C90'
                      isRound={currentFeedScene.webcam_type === 'round'}
                      isVerticalVideo={isVerticalVideo}
                      onDragPositionChange={(values) => {
                        updateDraggableSecond(values);
                      }}
                    />
                  }
                </div>
                <div className="player-controls-bottom">
                  <Select
                    className="editor-control-select camera-feed"
                    classNamePrefix="editor-control-select-prefix"
                    value={currentFeedScene?.webcam_enabled ? webcam_ratio_menu_items.find(el => el.value === webcamRatioMenuItem) : { label: 'None', value: 'none' }}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //  @ts-ignore 
                    onChange={(result) => updateCameraFeed(result?.value || 'none')}
                    options={webcam_ratio_menu_items}
                    components={{ DropdownIndicator }}
                  />
                  <Select
                    className="editor-control-select content-feed"
                    classNamePrefix="editor-control-select-prefix"
                    value={content_ratio_menu_items.find(el => el.value === convertFeedTypeToRatio(currentFeedScene.feed_type))}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //  @ts-ignore 
                    onChange={(result) => updateContentFeed(result?.value || 'none')}
                    options={content_ratio_menu_items}
                    components={{ DropdownIndicator }}
                  />
                  {/* <Tooltip title='Save Template' placement='top'>
                    <div onClick={saveTemplate} className="editor-control-button">
                      <TemplateSaveIcon />
                    </div>
                  </Tooltip>
                  <Tooltip title='Load Template' placement='top'>
                    <div onClick={loadTemplate} className="editor-control-button">
                      <TemplateReloadIcon />
                    </div>
                  </Tooltip> */}
                </div>
              </div>


              <div className='editor-output-container'>
                <div style={{ height: VIDEO_HEIGHT }} className="editor-output-container__divider"></div>
                <div style={styles.mobileViewContainer}>
                  <div className='player-name-label'>Vertical Video</div>
                  <div style={styles.mobileView}>
                    {subtitleState ? <div className='subtitles-preview-container'>
                      {featureSliders.map((feature: any) => (
                        <>
                          {feature.type === 'text' && <HiddenTextField textFeatureSlider={feature} type='mobile' playerWidth={VIDEO_WIDTH} isVerticalVideo={isVerticalVideo} />}
                        </>
                      ))}
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                      {/* @ts-ignore */}
                      <SubtitlesPreview playerWidth={VIDEO_WIDTH} sentences={sentences} playerRef={playerRef} updateSentences={updateSentences} componentHeight={VIDEO_HEIGHT} emojiEnabled={emojiEnabled}
                        animationSelected={animation} rightToLeft={rightToLeft} isVerticalVideo={isVerticalVideo}
                      />
                    </div> : <></>}

                    {blurFeed &&
                      <canvas ref={fullCanvasRef} width={MOBILE_VIDEO_WIDTH} height={VIDEO_HEIGHT} style={styles.feedCanvas}></canvas>
                    }
                    {videoRes && videoReady &&
                      <ContentFeed
                        /* @ts-expect-error undefined */
                        canvasRef={contentCanvasRef}
                        defaultPosition={draggableFourth}
                        position={{ x: draggableFourth.x, y: draggableFourth.y }}
                        size={{ width: draggableFourth.width, height: draggableFourth.height }}
                        lockRatio={true}
                        ratio={parseFloat(convertFeedTypeToRatio(currentFeedScene.feed_type))}
                        borderColor='#05F29B'
                        isRound={false}
                        showOutline={showOutline}
                        isVerticalVideo={isVerticalVideo}
                        onDragPositionChange={(values) => {
                          values.x = values.x - 16 >= 0 ? values.x : 0;
                          updateDraggableFourth(values);
                        }}
                      />
                    }

                    {/* Feature sliders content */}
                    {videoRes && videoReady && featureSliders.map((feature: any, featureIndex: number) => feature.mobileDymension && (feature.imageUrl || feature.videoUrl || feature.text || feature.svgType) && (
                      (checkContentFeedVisibility(feature) &&
                        <ContentFeed
                          key={feature.id}
                          /* @ts-expect-error undefined */
                          canvasRef={null}
                          defaultPosition={feature.mobileDymension}
                          position={{ x: feature.mobileDymension.x, y: feature.mobileDymension.y }}
                          size={{ width: feature.mobileDymension.width, height: feature.mobileDymension.height }}
                          lockRatio={feature.type === 'text' || feature.type === 'logo' ? false : true}
                          ratio={feature.mobileDymension.ratio}
                          borderColor={feature.color}
                          isRound={false}
                          imageUrl={feature.imageUrl}
                          videoUrl={feature.videoUrl}
                          textFeatureSlider={feature.type === 'text' && feature}
                          svgType={feature.svgType}
                          svgText={feature.text}
                          showOutline={showOutline}
                          zIndex={featureIndex + 1}
                          videoStartEndTime={feature.startEndTime}
                          isMainPlayerPlaying={isPlaying}
                          playerWidth={VIDEO_WIDTH}
                          disableDragging={feature?.position && feature?.position !== 'custom'}
                          currentSeekedTimeOfMainPlayer={currentSeekedTimeOfMainPlayer}
                          isVerticalVideo={isVerticalVideo}
                          onDragPositionChange={(values) => {
                            values.x = values.x - 16 >= 0 ? values.x : 0;
                            updateSliderFeatureMobileDimension(feature.id, values);
                          }}
                        />)
                    ))}

                    {currentFeedScene?.webcam_enabled && currentFeedScene.webcam_type === 'round' && videoRes && videoReady &&
                      <ContentFeed
                        /* @ts-expect-error undefined */
                        canvasRef={streamerRoundCanvasRef}
                        defaultPosition={draggableThird}
                        position={{ x: draggableThird.x, y: draggableThird.y }}
                        size={{ width: draggableThird.width, height: draggableThird.height }}
                        lockRatio={true}
                        ratio={1}
                        borderColor='#FF2C90'
                        isRound={true}
                        showOutline={showOutline}
                        isVerticalVideo={isVerticalVideo}
                        onDragPositionChange={(values) => {
                          values.x = values.x - 16 >= 0 ? values.x : 0;
                          updateDraggableThird(values);
                        }}
                      />
                    }

                    {currentFeedScene?.webcam_enabled && currentFeedScene.webcam_type !== 'round' && videoRes && videoReady &&
                      <ContentFeed
                        /* @ts-expect-error undefined */
                        canvasRef={streamerCanvasRef}
                        defaultPosition={draggableThird}
                        position={{ x: draggableThird.x, y: draggableThird.y }}
                        size={{ width: draggableThird.width, height: draggableThird.height }}
                        lockRatio={true}
                        ratio={parseFloat(webcamRatio)}
                        borderColor='#FF2C90'
                        isRound={false}
                        showOutline={showOutline}
                        isVerticalVideo={isVerticalVideo}
                        onDragPositionChange={(values) => {
                          values.x = values.x - 16 >= 0 ? values.x : 0;
                          updateDraggableThird(values);
                        }}
                      />
                    }
                  </div>
                </div>
              </div>


            </div>

            <div className="player-timeline-container">
              {featureSliders && setFeatureSliders &&
                <VideoTimeline
                  progress={playerProps.progress}
                  duration={playerProps.duration}
                  playing={playerProps.playing}
                  onPlayPause={onPlayPause}
                  onSeek={onSeek}
                  featureSliders={featureSliders}
                  setFeatureSliders={setFeatureSliders}
                  trimValues={playerProps.trimValues}
                  trimValuesProp={trimValues}
                  handleDeleteFeatureSlider={handleDeleteFeatureSlider}
                  handleSliderTimeChange={handleFeatureSliderTimeChange}
                  handleTrimmingBarChange={handleTrimmingBarChange}
                  handleSplitVideo={handleSplitVideo}
                  // feedScenesBreakpoints={feedScenesBreakpoints}
                  handleDeleteFeedScene={handleDeleteFeedScene}
                  handleHideFeedScene={handleHideFeedScene}
                  playerLoadedSeconds={playerLoadedSeconds}
                  allFeedScenes={allFeedScenes}
                  handleRevertFeedScene={handleRevertFeedScene}
                  currentFeedScene={currentFeedScene}
                  disableScenes={clip.clip_type === 'spike'}
                  handleDeleteAllScenes={handleDeleteAllScenes}
                  isEditorFocused={isEditorFocused}
                  audioWaveform={clip?.audio_decibels}
                />
              }
              {/* Bottom part timeline goes here */}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

const FieldDifference = ({ label, oldValue, newValue }: { label: string, oldValue: string | undefined, newValue: string | undefined }) => {
  const fieldNotChanged = oldValue === newValue;
  return (
    <div className='diff-row'>
      <span className='diff-label'>{label}:</span>
      <span>{oldValue || 'unset'}</span>
      {!fieldNotChanged &&
        <>
          <RightArrowIcon />
          <span>{newValue || 'unset'}</span>
        </>
      }
    </div>
  )
};

const HiddenTextField = ({ textFeatureSlider, type, playerWidth, isVerticalVideo }: { textFeatureSlider: any, type: 'desktop' | 'mobile', playerWidth: number, isVerticalVideo: boolean }) => {
  return (
    <div className="hidden-text-field">
      <div style={{
        fontSize: makeFontSizeResponsive(textFeatureSlider?.fontSize, playerWidth, isVerticalVideo),
        fontWeight: textFeatureSlider?.fontWeight,
        // textShadow: textShadowPreset[textFeatureSlider?.textShadow as keyof typeof textShadowPreset],
        fontFamily: textFeatureSlider?.fontFamily,
        color: textFeatureSlider?.fontColor,
        textTransform: textFeatureSlider?.textTransform,
        overflow: 'hidden',
        maxHeight: '100%',
        wordBreak: 'break-all',
        // '--text-shadow-color': textFeatureSlider.textShadowColor,
      }} id={`text-${type}-${textFeatureSlider.id}`} className="hidden-text-content">{textFeatureSlider.text}</div>
    </div>
  )
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


export default EditorPage;
